const mutations = {
  setBbarshopMenus(state, payload) {
    state.bbarshopMenus = payload;
  },
  setFilterValue(state, payload) {
    state.filterValue = payload;
  },
  setCategory(state, payload) {
    state.category = payload;
  },
  setStore(state, payload) {
    state.store = payload;
  },
  setSearchStoreId(state, payload) {
    state.searchStoreId = payload;
  },
  setStoreDetail(state, payload) {
    state.storeDetail = payload;
  },
  setPrice(state, payload) {
    state.price = payload;
  },
  setSortType(state, payload) {
    state.sortType = payload;
  },
  bsProductList(state, payload) {
    state.bsProductList = payload;
  },
  bsProductCount(state, payload) {
    state.bsProductCount = payload;
  },
  zzimProductList(state, payload) {
    state.zzimProductList = payload;
  },
  zzimStoreList(state, payload) {
    state.zzimStoreList = payload;
  },
  recentViewList(state, payload) {
    state.recentViewList = payload;
  },
  storeList(state, payload) {
    state.storeList = payload;
  },
  bsProductRollingList(state, payload) {
    state.bsProductRollingList = payload;
  },
  bsProductGridList(state, payload) {
    state.bsProductGridList = payload;
  },
  bsProductDesignList(state, payload) {
    state.bsProductDesignList = payload;
  },
  bsMagazineAList(state, payload) {
    state.bsMagazineAList = payload;
  },
  bsMagazineBList(state, payload) {
    state.bsMagazineBList = payload;
  },
  bsPassiveComponent(state, payload) {
    state.bsPassiveComponent = payload;
  },
  setComponentData(state, payload) {
    state.componentData = payload;
  },
  recentSearches(state, payload) {
    state.recentSearches = payload;
  },
  bsKeyword(state, payload) {
    state.bsKeyword = payload;
  },
  bsExhibitionCategory(state, payload) {
    state.bsExhibitionCategory = payload;
  },
  bsProductDetailList(state, payload) {
    state.bsProductDetailList = payload;
  },
  setComponentList(state, payload) {
    state.componentList = payload;
  },
  setPassiveList(state, payload) {
    state.passiveList = payload;
  },
  bsExhibitionParams(state, payload) {
    state.bsExhibitionParams.push(payload);
  },
  bsExhibitionAList(state, payload) {
    state.bsExhibitionAList = payload;
  },
  bsCategoryIndex(state, payload) {
    state.bsCategoryIndex = payload;
  },
  productData(state, payload) {
    state.productData = payload;
  },
  setPointshopList(state, payload) {
    state.pointShopList = payload;
  },

  //최근검색어 목록 추가
  addRecentSearches(state, searchData) {
    if (typeof searchData === 'string') {
      const isEqual = el => el === searchData;

      let index = state.recentSearches.findIndex(isEqual);
      if (index > -1) {
        state.recentSearches.splice(index, 1);
      }
    } else if (typeof searchData === 'object') {
      state.recentSearches.forEach((data, idx) => {
        if (JSON.stringify(data) === JSON.stringify(searchData)) {
          state.recentSearches.splice(idx, 1);
        }
      });
    }
    state.recentSearches.unshift(searchData);
    if (state.recentSearches.length > 10) {
      state.recentSearches.length = 10;
    }

    const parsed = JSON.stringify(state.recentSearches);
    localStorage.setItem('recentSearches', parsed);
    // localStorage.setItem('recentSearches', state.recentSearches);
  },

  //최근검색어 목록 전체 삭제
  clearRecentSearches(state) {
    state.recentSearches = [];

    const parsed = JSON.stringify(state.recentSearches);
    localStorage.setItem('recentSearches', parsed);
    // localStorage.clear('recentSearches');
  },

  //최근검색어 목록 삭제
  removeRecentSearches(state, idx) {
    state.recentSearches.splice(idx, 1);

    const parsed = JSON.stringify(state.recentSearches);
    localStorage.setItem('recentSearches', parsed);
    // localStorage.removeItem('recentSearches', idx);
  },
  setProductBuyAttempt(state, payload) {
    state.productBuyAttempt = payload;
  },
  setUserSelect(state, payload) {
    state.userSelect = payload;
  },
  setRecntViewPath(state, payload) {
    state.recntViewPath = payload;
  },
  setProductDetailIsBack(state, payload) {
    state.productDetailIsBack = payload;
  },
  setReZzimProduct(state, payload) {
    state.reZzimProduct = payload;
  },
  setTypePath(state, payload) {
    state.typePath = payload;
  },
  setSelectedAddr(state, payload) {
    state.selectedAddr = payload;
  },
  setChangeAddr(state, payload) {
    state.changeAddr = payload;
  },
  setSelectedAddrIdx(state, payload) {
    state.selectedAddrIdx = payload;
  },
  setMallCode(state, payload) {
    state.mallCode = payload;
  },
  setOrderPath(state, payload) {
    state.orderPath = payload;
  },
  setAvailableDates(state, payload) {
    state.availableDates = payload;
  },
  setUnAvailableDates(state, payload) {
    state.unAvailableDates = payload;
  },
  setSelectedDate(state, payload) {
    state.selectedDate = payload;
  },
  setProductInform(state, payload) {
    state.productInform = payload;
  },
  setRecommendProductRollingList(state, payload) {
    state.productRollingList = payload;
  },
  setCategory1(state, payload) {
    state.category1 = payload;
  },
  setCategory2(state, payload) {
    state.category2 = payload;
  },
  setCategory3(state, payload) {
    state.category3 = payload;
  },
  setPersonalCode(state, payload) {
    state.personalCode = payload;
  },
};
export default mutations;
