import axios from './common/apiConfig';
import urlConfig from '@/config/urlConfig';

//post 안대믄 postQs 쓰기

//빠숍 상품목록 조회
async function bsProductList(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}products${params}`);
}

//상품목록 전체 갯수 조회
async function bsProductCount(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}products/count${params}`);
}

//상품 상세 조회
async function bsProductDetail(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}products/${params}`);
}

//스토어 필터조회
async function storeFilters(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}products/filter?type=store${params}`);
}

//가격 필터조회
async function priceFilters(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}products/filter?type=price${params}`);
}

// 스토어 목록 조희
async function bsStoreList(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}companys${params}`);
  // return await axios.get(`${urlConfig.BS_BASE_URL}companys`);
}

// 스토어 목록 조희
async function bsStoreDetail(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}companys/${params}`);
}

//쇼핑태그 목록 조회
async function bsTagList(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}tags?tag=${params}&per_page=30`);
}

//최근 본 상품 갯수 조회
async function recentViewCount() {
  return await axios.get(`${urlConfig.BS_BASE_URL}recent_products/count`);
}

//최근 본 상품 목록 조회
async function recentViewList() {
  return await axios.get(`${urlConfig.BS_BASE_URL}recent_products`);
}

//최근 본 상품 등록
async function recentViewRegister(params) {
  return await axios.post(`${urlConfig.BS_BASE_URL}recent_products`, params);
}

//최근 본 상품 삭제
async function recentViewDelete(params) {
  return await axios.delete(`${urlConfig.BS_BASE_URL}recent_products/${params}`);
}

//찜한 상품 목록 조회
async function zzimProductList() {
  // return await axios.get(`${urlConfig.BS_BASE_URL}like_products`);
  return await axios.get(`${urlConfig.BS_BASE_URL}like_products?per_page=100`);
}

//상품 찜 등록
async function zzimProductRegister(params) {
  // return await axios.postQs(`${urlConfig.BS_BASE_URL}like_products`, params);
  return await axios.post(`${urlConfig.BS_BASE_URL}like_products`, params);
}

//상품 찜 해제
async function zzimProductDelete(params) {
  return await axios.update(`${urlConfig.BS_BASE_URL}like_products/${params.id}`, params.like_product);
}

//찜한 스토어 목록 조회
async function zzimStoreList() {
  return await axios.get(`${urlConfig.BS_BASE_URL}like_companys`);
}

//찜한 스토어 갯수 조회
async function zzimStoreLength() {
  return await axios.get(`${urlConfig.BS_BASE_URL}like_companys/count`);
}

//빠숍 스토어 찜 등록
async function zzimStoreRegister(params) {
  return await axios.postQs(`${urlConfig.BS_BASE_URL}like_companys`, params);
}

//빠숍 스토어 찜 해제
async function zzimStoreDelete(params) {
  return await axios.delete(`${urlConfig.BS_BASE_URL}like_companys/${params.like_company.company_id}`);
}

//인기스토어 목록 조회
async function popularStoreList() {
  return await axios.get(`${urlConfig.BS_BASE_URL}top_companys`);
}

//인기 검색어 목록 조회
async function popularSearchList() {
  return await axios.get(`${urlConfig.BS_BASE_URL}popular_searches`);
}

//빠숍 검색 추천 키워드 목록보기
async function bsKeyword() {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}common/keyword`);
}

//자동완성
async function autoComplete(params) {
  return await axios.post(`${urlConfig.HB_BASE_URL}autocomplete`, params);
}

//상품 구매 시도 등록
async function trades(params) {
  return await axios.post(`${urlConfig.BS_BASE_URL}trades`, params);
}

//이벤트(페이지 이동 등) 발생 시 호출 공통
async function informEvent(params) {
  return await axios.post(`${urlConfig.BS_BASE_URL}statistics/informEvent`, params);
}

// 검색결과 상품상세 조회 정보 전달
async function viewSearchProduct(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}statistics/view_search_product/${params}`);
}

//상품 구매 완료 등록
async function orderComplete(params) {
  return await axios.update(`${urlConfig.BS_BASE_URL}trades/${params.id}`, params);
}

// 상품 공유 시도
async function productShareAttempt(params) {
  return await axios.get(`${urlConfig.BS_BASE_URL}statistics/share_product/${params}`);
}

// 개인통관부호 조회
async function personalCodeGet(params) {
  return await axios.get(`${urlConfig.PAI_BASE_URL}pi`);
}

// 개인통관부호 등록
async function personalCodeAdd(params) {
  return await axios.post(`${urlConfig.PAI_BASE_URL}pi`, params);
}

// 개인통관부호 수정
async function personalCodeUpdate(params) {
  return await axios.update(`${urlConfig.PAI_BASE_URL}pi`, params);
}

// 개인통관부호 삭제
async function personalCodeDelete(params) {
  return await axios.delete(`${urlConfig.PAI_BASE_URL}`, params);
}
export {
  bsProductList,
  bsProductCount,
  bsProductDetail,
  storeFilters,
  priceFilters,
  bsStoreList,
  bsStoreDetail,
  bsTagList,
  recentViewCount,
  recentViewList,
  recentViewRegister,
  recentViewDelete,
  zzimProductList,
  zzimProductRegister,
  zzimProductDelete,
  zzimStoreList,
  zzimStoreRegister,
  zzimStoreDelete,
  popularStoreList,
  popularSearchList,
  bsKeyword,
  autoComplete,
  zzimStoreLength,
  informEvent,
  trades,
  viewSearchProduct,
  orderComplete,
  productShareAttempt,
  personalCodeGet,
  personalCodeAdd,
  personalCodeUpdate,
  personalCodeDelete,
};
