<template>
  <div style="padding-top: 24px">
    <div class="d-flex c-center r-center">
      <img :src="require('@/assets/img/bbarshop/btn_arr_low4.svg')" alt="" style="" @click="clickEvent('closePopup')" />
    </div>
    <div style="margin-top: 24px; margin-right: 32px; margin-left: 32px; border: solid 2px #f2f3f5">
      <div
        class="d-flex c-center r-space-between"
        style="margin-left: 28px; margin-right: 28px; height: auto; padding-top: 28px; padding-bottom: 28px"
      >
        <div class="d-flex c-center r-space-between">
          <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.4" font-weight="bold" style="line-height: 1.36; margin-right: 12px"
            >주문 상품</CommonText
          >
          <div style="width: 2px; height: 26px; background-color: #f2f3f5"></div>
          <CommonText
            font-size="28"
            font-color="#2b2d2f"
            spacing="-1.4"
            font-weight=""
            style="line-height: 1.29; margin-left: 12px; width: 405px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
            >{{ $store.state.bbarshop.productData.name }}</CommonText
          >
        </div>
        <CommonText font-size="28" font-color="#76787a" spacing="-1.4" font-weight="" style="line-height: 1.29; font-family: Pretendard"
          >{{ $store.state.bbarshop.userSelect.userCount }}개</CommonText
        >
      </div>
      <BorderBasic height="2" background="#f2f3f5" />
      <div class="column__addr" style="height: auto">
        <div class="d-flex c-center r-space-between" style="padding: 29px">
          <div v-if="isAddrEmpty">
            <div class="d-flex c-center">
              <CommonText
                font-size="28"
                font-color="#2b2d2f"
                spacing="-1.4"
                font-weight="bold"
                style="line-height: 1.36; margin-right: 12px"
                >배송지</CommonText
              >
              <div style="width: 2px; height: 26px; background-color: #f2f3f5"></div>
              <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.4" font-weight="" style="line-height: 1.29; margin-left: 12px"
                >배송지 등록이 필요합니다</CommonText
              >
            </div>
          </div>
          <div v-else>
            <div v-if="hasDefaultAddr">
              <div class="d-flex c-center">
                <CommonText
                  font-size="28"
                  font-color="#2b2d2f"
                  spacing="-1.4"
                  font-weight="bold"
                  style="line-height: 1.36; margin-right: 12px"
                  >배송지</CommonText
                >
                <div style="width: 2px; height: 26px; background-color: #f2f3f5"></div>
                <!--																<div v-if="showAddrMore" class="d-flex" @click="clickEvent('addrRegister')">-->
                <div v-if="!$refs.addrMore.classList.contains('off')" class="d-flex" @click="clickEvent('addrRegister')">
                  <CommonText font-size="24" font-color="#648cff" spacing="-1.2" font-weight="" style="line-height: 1.5; margin-left: 12px"
                    >신규 배송지 등록</CommonText
                  >
                  <img src="@/assets/img/bbarshop/img_arr_right5.svg" alt="" />
                </div>
                <div v-else class="d-flex">
                  <CommonText
                    font-size="28"
                    font-color="#2b2d2f"
                    spacing="-1.4"
                    font-weight=""
                    style="line-height: 1.29; margin-left: 12px"
                    >{{ defaultAddr.recipientName }}</CommonText
                  >
                  <CommonText
                    font-size="28"
                    font-color="#2b2d2f"
                    spacing="-1.4"
                    font-weight=""
                    style="line-height: 1.29; margin-left: 4px; font-family: Pretendard"
                    >{{ defaultAddr.orderCelNo }}</CommonText
                  >
                </div>
              </div>
              <!--							<div v-if="!showAddrMore">-->
              <div v-if="$refs.addrMore.classList.contains('off')" class="d-flex" @click="clickEvent('addrRegister')">
                <CommonText
                  font-size="28"
                  font-color="#76787a"
                  spacing="-1.4"
                  font-weight=""
                  style="
                    width: 500px;
                    line-height: 1.29;
                    margin-top: 12px;
                    font-family: Pretendard;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  >{{
                    '[' + defaultAddr.orderZipcode + ']' + ' ' + defaultAddr.orderAddrBasic + ' ' + defaultAddr.orderAddrDetail
                  }}</CommonText
                >
              </div>
            </div>
          </div>
          <img v-if="addrOption" :src="require('@/assets/img/fit/btn_arr_up.svg')" alt="" @click="clickEvent('addrChange')" />
          <div v-else-if="$commons.isNull(addrList.list)" class="btn-change d-flex c-center r-center" @click="clickEvent('addrRegister')">
            등록
          </div>
          <div v-else class="btn-change d-flex c-center r-center" @click="clickEvent('addrChange')">변경</div>
        </div>
        <div ref="addrMore" class="column__addr__more off" @scroll="hasScrolled">
          <div v-for="(item, idx) in addrList.list" :key="idx">
            <div class="d-flex section" style="padding: 20px 30px 20px 28px">
              <div class="d-flex c-center" @click="clickEvent('radioCheck', idx, item)">
                <input
                  :id="item.orderAddrSeq"
                  :ref="item.orderAddrSeq"
                  type="radio"
                  name="addr"
                  class="check-all"
                  :value="item.orderAddrSeq"
                  :checked="idx === 0"
                />
                <!-- :checked="item.defaultAddrYN === 'Y'" -->
                <!-- dh -->
                <label :for="item.orderAddrSeq"></label>
              </div>
              <div>
                <div></div>
                <CommonText
                  font-size="28"
                  font-color="#2b2d2f"
                  style="line-height: 1.29; letter-spacing: -1.4px; font-family: Pretendard"
                  >{{ item.orderZipcode + item.orderAddrBasic + ' ' + item.orderAddrDetail }}</CommonText
                >
                <div class="d-flex c-center">
                  <CommonText
                    font-size="28"
                    font-color="#76787a"
                    style="font-family: Pretendard; line-height: 1.29; letter-spacing: -1.4px; margin-right: 12px"
                    >{{ item.recipientName }}</CommonText
                  >
                  <div style="width: 2px; height: 26px; background-color: #dadcde"></div>
                  <CommonText
                    font-size="28"
                    font-color="#76787a"
                    style="font-family: Pretendard; line-height: 1.29; letter-spacing: -1.4px; margin-left: 12px"
                    >{{ item.orderCelNo }}</CommonText
                  >
                </div>
              </div>
            </div>
            <BorderBasic margin="0" height="0" background="" style="border: solid 1px #dadcde; margin: 0 20px" />
          </div>
        </div>
        <!-- <div class="btn-change d-flex c-center r-center" @click="clickEvent('changeAddr')">변경</div> -->
      </div>
      <!--   개인통관고유부호   -->
      <div v-if="$store.state.bbarshop.productData.company_id == 69">
        <BorderBasic height="2" background="#f2f3f5" />
        <div class="d-flex c-center r-space-between" style="padding: 29px">
          <div>
            <div class="d-flex c-center">
              <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.4" font-weight="bold" style="line-height: 1.36; width: 111px"
                >개인통관고유부호</CommonText
              >
              <div style="width: 2px; height: 26px; background-color: #f2f3f5; margin: 0 12px"></div>
              <CommonText v-if="personalCode && !deleteData" font-size="28" font-color="#2b2d2f" spacing="-1.4 "
                >{{ personalCode }}
              </CommonText>
              <CommonText
                v-else
                font-size="28"
                font-color="#2b2d2f"
                spacing="-1.4"
                font-weight=""
                style="line-height: 1.29; margin-left: 12px; max-width: 366px"
                >개인통관고유부호 등록이<br />필요합니다</CommonText
              >
            </div>
          </div>
          <div
            v-if="personalCode && changePersonalCode && !deleteData"
            class="btn-change d-flex c-center r-center"
            @click="clickEvent('changePersonalCode')"
          >
            변경
          </div>
          <div
            v-else-if="personalCode == '' || deleteData"
            class="btn-change d-flex c-center r-center"
            @click="clickEvent('changePersonalCode')"
          >
            등록
          </div>
          <img v-else :src="require('@/assets/img/fit/btn_arr_up.svg')" alt="" @click="clickEvent('changePersonalCode')" />
        </div>
        <personal-code-frame
          v-if="!changePersonalCode"
          :delete-data="deleteData"
          :btn-click="btnClick"
          style="padding: 0 40px 40px"
          @btn-activate="btnActive"
        />
      </div>
      <BorderBasic v-if="isUseTVpoint" height="2" background="#f2f3f5" />
      <div v-if="isUseTVpoint" class="d-flex c-center r-space-between" style="margin-left: 28px; margin-right: 28px; height: 90px">
        <div class="d-flex c-center r-space-between">
          <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.4" font-weight="bold" style="line-height: 1.36; margin-right: 12px"
            >TV포인트</CommonText
          >
          <div style="width: 2px; height: 26px; background-color: #f2f3f5"></div>
          <img
            :src="require(`@/assets/img/common/check_circle_${tpStatus}.svg`)"
            class=""
            style="margin-left: 12px"
            alt=""
            @click="clickEvent('tpStatus')"
          />
          <CommonText
            font-size="28"
            font-color="#2b2d2f"
            spacing="-1.4"
            font-weight="500"
            style="line-height: 1.36; margin-left: 8px; font-family: Pretendard"
            >{{ $utils.formatComma(userPoint) }}P</CommonText
          >
          <CommonText
            font-size="28"
            font-color="#76787a"
            spacing="-1.12"
            font-weight="500"
            style="line-height: 1.36; margin-left: 2px; font-family: Pretendard"
            >사용</CommonText
          >
        </div>
        <div class="d-flex c-center" @click="clickEvent('exchangePoint')">
          <CommonText font-size="24" font-color="#76787a" spacing="-1.2" font-weight="" style="line-height: 1.5; margin-right: 4px"
            >숨겨진</CommonText
          >
          <CommonText font-size="24" font-color="#2b2d2f" spacing="-1.2" font-weight="500" style="line-height: 1.5; margin-right: 4px"
            >{{ $utils.formatComma($store.state.tvpoint.transablePoint) }}P</CommonText
          >
          <CommonText font-size="24" font-color="#76787a" spacing="-1.2" font-weight="500" style="line-height: 1.5; font-family: Pretendard"
            >찾기</CommonText
          >
          <img ref="store" :src="require('@/assets/img/bbarshop/img_arr_right6.svg')" class="" alt="" @click="clickEvent('')" />
        </div>
      </div>
      <BorderBasic height="2" background="#f2f3f5" />
      <div v-if="paymentOption" class="d-flex c-center r-space-between" style="margin-left: 28px; margin-right: 28px; height: 90px">
        <!-- <div v-if="!this.card" class="d-flex c-center r-space-between" style="margin-left: 28px; margin-right: 28px; height: 90px"> -->
        <div class="d-flex c-center r-space-between">
          <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.4" font-weight="bold" style="line-height: 1.36">결제수단</CommonText>
          <div class="d-flex" @click="clickEvent('orderPaymentDetail')">
            <CommonText font-size="24" font-color="#648cff" spacing="-1.2" font-weight="" style="line-height: 1.5; margin-left: 12px"
              >다른 결제수단으로 변경</CommonText
            >
            <img :src="require('@/assets/img/bbarshop/img_arr_right5.svg')" class="" style="" alt="" />
          </div>
        </div>
        <img
          ref="option"
          :src="require('@/assets/img/fit/btn_arr_low.svg')"
          class="column__title__arrow"
          alt=""
          @click="clickEvent('paymentOption')"
        />
      </div>
      <div v-else class="d-flex c-center r-space-between" style="margin-left: 28px; margin-right: 28px; height: 90px">
        <div class="d-flex c-center r-space-between">
          <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.4" font-weight="bold" style="line-height: 1.36; margin-right: 12px"
            >결제수단</CommonText
          >
          <div style="width: 2px; height: 26px; background-color: #f2f3f5"></div>
          <CommonText
            v-if="!$commons.isNull($store.state.orderPayment.selectCard.cardCode)"
            font-size="26"
            font-color="#2b2d2f"
            spacing="-1.3"
            font-weight=""
            style="line-height: 1.38; margin-left: 12px; font-family: Pretendard"
            >빠른결제</CommonText
          >
          <CommonText
            font-size="26"
            font-color="#76787a"
            spacing="-1.3"
            font-weight=""
            style="line-height: 1.38; margin-left: 8px; font-family: Pretendard"
            >{{ !$commons.isNull(card) && card.cardCode !== 'P088' ? cardName(card.cardCode) : selectCardName }}</CommonText
          >
          <CommonText
            v-if="!$commons.isNull(card)"
            font-size="26"
            font-color="#76787a"
            spacing="-1.3"
            font-weight=""
            style="
              width: 191px;
              line-height: 1.38;
              margin-left: 4px;
              font-family: Pretendard;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            >{{
              card.cardCode !== 'P088' ? card.goodsName + '/' + $store.state.orderPayment.selectDiscount.name : card.acntName
            }}</CommonText
          >
        </div>
        <!-- <div class="btn-change d-flex c-center r-center" @click="clickEvent('changeCard')">변경</div> -->
        <div ref="option" class="btn-change d-flex c-center r-center" @click="clickEvent('paymentOption')">변경</div>
        <!-- <img
          :src="require('@/assets/img/fit/btn_arr_low.svg')"
          class="column__title__arrow"
          ref="option"
          @click="clickEvent('paymentOption')"
          alt=""
        /> -->
      </div>
      <BorderBasic height="2" background="#f2f3f5" />
      <CardRollingContentWrapper
        v-if="paymentOption"
        ref="bannerSwiper"
        :type="'card-wrapper'"
        :pay="paymentPrice >= 50000"
        :card-idx="cardIdx"
        @click-event="clickEvent"
        @card-actvie-index="cardActvieIndex"
        @on-click-pay-state="onClickPayState"
      />
      <div class="d-flex c-center r-space-between" style="margin-left: 28px; margin-right: 28px; height: 90px">
        <div class="d-flex c-center r-space-between">
          <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.4" font-weight="bold" style="line-height: 1.36; margin-right: 16px"
            >결제금액</CommonText
          >
          <div class="btn d-flex c-center r-center" @click="clickEvent('receipt')">상세내역</div>
        </div>
        <div class="d-flex">
          <!-- <div class="discount-box d-flex c-center r-center" style="margin-right: 8px">
            <CommonText fontSize="22" fontColor="#2b2d2f" spacing="-1.1" fontWeight="600"
              >최대<span style="color: #648cff">{{ this.$store.state.bbarshop.productData.discount_rate }}%</span>적립</CommonText
            >
          </div> -->
          <CommonText
            font-size="32"
            font-color="#2b2d2f"
            spacing="-1.6"
            font-weight="bold"
            style="line-height: 1.19; text-align: right; font-family: Pretendard"
            >{{ $utils.formatComma(paymentPrice) }}원</CommonText
          >
        </div>
      </div>
    </div>
    <div class="d-flex c-center" style="margin: 40px 71px 24px">
      <CommonText font-size="20" font-color="#76787a" spacing="-1" font-weight="" style="line-height: 1.8; font-family: Pretendard"
        >위 주문 내용을 확인 하였으며,&nbsp;</CommonText
      >
      <CommonText
        font-size="20"
        font-color="#76787a"
        spacing="-1"
        font-weight=""
        style="line-height: 1.8; font-family: Pretendard; text-decoration: underline"
      >
        구매조건, 결제대행 서비스</CommonText
      >
      <CommonText font-size="20" font-color="#76787a" spacing="-1" font-weight="" style="line-height: 1.8; font-family: Pretendard">
        &nbsp;및 결제에 동의합니다.</CommonText
      >
    </div>
    <div class="btn-bottom" @click="clickEvent('pay')">{{ $utils.formatComma(paymentPrice) }}원 결제하기</div>

    <transition name="fade">
      <div v-if="receipt" id="layerWrap" class="layer-wrap">
        <!--        아래 이미지 띄워야함-->
        <div class="pop-layer" style="position: relative">
          <img
            :src="require(`@/assets/img/bbarshop/btn_close_white_b.svg`)"
            style="position: absolute; top: -74px; right: 0"
            class=""
            alt=""
            @click="clickEvent('receipt')"
          />
          <div style="padding: 51px 48px 0 48px">
            <div class="d-flex c-center r-space-between" style="margin-bottom: 24px">
              <div class="d-flex c-center r-space-between">
                <CommonText
                  font-size="28"
                  font-color="#2b2d2f"
                  spacing="-1.4"
                  font-weight="500"
                  style="line-height: 1.29; margin-right: 16px"
                  >판매가</CommonText
                >
              </div>
              <div>
                <CommonText
                  font-size="32"
                  font-color="#2b2d2f"
                  spacing="-1.6"
                  font-weight="bold"
                  style="line-height: 1.19; text-align: right; font-family: Pretendard"
                  >{{
                    $utils.formatComma($store.state.bbarshop.productData.price_original * $store.state.bbarshop.userSelect.userCount)
                  }}원</CommonText
                >
              </div>
            </div>
            <div class="d-flex c-center r-space-between" style="margin-bottom: 24px">
              <div class="d-flex c-center r-space-between">
                <CommonText
                  font-size="28"
                  font-color="#2b2d2f"
                  spacing="-1.4"
                  font-weight="500"
                  style="line-height: 1.29; margin-right: 16px"
                  >상품할인</CommonText
                >
              </div>
              <div>
                <CommonText
                  font-size="32"
                  font-color="#2b2d2f"
                  spacing="-1.6"
                  font-weight="bold"
                  style="line-height: 1.19; text-align: right; font-family: Pretendard"
                  >{{
                    $utils.formatComma(
                      ($store.state.bbarshop.productData.price_discount - $store.state.bbarshop.productData.price_original) *
                        $store.state.bbarshop.userSelect.userCount,
                    )
                  }}원</CommonText
                >
              </div>
            </div>
            <div
              v-if="tpStatus === 'on' && $store.state.bbarshop.storeDetail.in_app_pay_options.pay_types.includes(4)"
              class="d-flex c-center r-space-between"
              style="margin-bottom: 24px"
            >
              <div class="d-flex c-center r-space-between">
                <CommonText
                  font-size="28"
                  font-color="#2b2d2f"
                  spacing="-1.4"
                  font-weight="500"
                  style="line-height: 1.29; margin-right: 16px"
                  >TV포인트 사용</CommonText
                >
              </div>
              <div>
                <CommonText
                  v-if="tpStatus === 'on'"
                  font-size="32"
                  font-color="#2b2d2f"
                  spacing="-1.6"
                  font-weight="bold"
                  style="line-height: 1.19; text-align: right; font-family: Pretendard"
                  >-{{ $utils.formatComma(userPoint) }}원</CommonText
                >
                <CommonText
                  v-else
                  font-size="32"
                  font-color="#2b2d2f"
                  spacing="-1.6"
                  font-weight="bold"
                  style="line-height: 1.19; text-align: right; font-family: Pretendard"
                  >0원</CommonText
                >
              </div>
            </div>
            <div class="d-flex c-center r-space-between" style="margin-bottom: 12px">
              <div class="d-flex c-center r-space-between">
                <CommonText
                  font-size="28"
                  font-color="#2b2d2f"
                  spacing="-1.4"
                  font-weight="500"
                  style="line-height: 1.29; margin-right: 16px"
                  >배송비</CommonText
                >
              </div>
              <div>
                <CommonText
                  font-size="32"
                  font-color="#2b2d2f"
                  spacing="-1.6"
                  font-weight="bold"
                  style="line-height: 1.19; text-align: right; font-family: Pretendard"
                  >{{ $utils.formatComma($store.state.bbarshop.productData.shipping.shipping_cost) }}원</CommonText
                >
              </div>
            </div>
            <div v-if="$store.state.bbarshop.productData.shipping.delivery_grade" class="deliveryFee">
              └
              {{ $store.state.bbarshop.productData.shipping.delivery_detail }}
            </div>
            <BorderBasic margin="0" height="0" background="" style="border: 2px dashed #f2f3f5; margin-top: 48px" />
            <div class="d-flex c-center r-space-between" style="margin-top: 40px">
              <div class="d-flex c-center r-space-between">
                <CommonText
                  font-size="28"
                  font-color="#2b2d2f"
                  spacing="-1.4"
                  font-weight="500"
                  style="line-height: 1.29; margin-right: 16px"
                  >결제금액</CommonText
                >
              </div>
              <div>
                <CommonText
                  font-size="32"
                  font-color="#648cff"
                  spacing="-1.6"
                  font-weight="bold"
                  style="line-height: 1.19; text-align: right; font-family: Pretendard"
                  >{{ $utils.formatComma(paymentPrice) }}원</CommonText
                >
              </div>
            </div>
          </div>
          <img :src="require(`@/assets/img/bbarshop/img_receipt_asset.svg`)" style="position: absolute; bottom: -79px" class="" alt="" />
        </div>
      </div>
    </transition>

    <SecureKeypadPass ref="keypad" :payment="payment" @has-order-path="hasOrderPath" />

    <object
      v-if="isShow"
      id="appCard"
      :data="appUrl"
      type="text/html"
      style="z-index: 9998; position: fixed; bottom: 0; left: 0; width: 100%; height: 100%"
    ></object>
  </div>
</template>

<script>
import OrderSelect from '@/components/orderPayment/orderSelect';
import ColumnHeader from '@/components/menu/ColumnHeader';
import CommonText from '@/components/common/text/CommonText';
import BorderBasic from '@/components/common/border/BorderBasic';
import CardRollingContentWrapper from '@/components/orderPayment/CardRollingContentWrapper';
import { mapGetters } from 'vuex';
import urlConfig from '@/config/urlConfig';
import bus from '@/utils/bus';
import $store from '@/store';
import { fitMixin } from '@/utils/fitMixin';
import _ from 'lodash';
import SecureKeypadPass from '@/components/tvpoint/common/SecureKeypadPass';
import $utils from '@/utils/tvPointcommon';
import PersonalCodeFrame from '@/components/bbarshop/orderPayment/PersonalCodeFrame';

export default {
  name: 'FastPayContent',
  components: {
    PersonalCodeFrame,
    CardRollingContentWrapper,
    // OrderSelect,
    // ColumnHeader,
    CommonText,
    BorderBasic,
    // OrderSelect,
    SecureKeypadPass,
  },

  mixins: [fitMixin],

  emits: ['closePopup'],

  data() {
    return {
      dataList: [],
      dataListGift: [],
      count: 1,
      btnMinusImg: require('@/assets/img/common/btn_minus.svg'),
      btnPlusImg: require('@/assets/img/common/btn_plus.svg'),
      optionTitle: '',
      tpStatus: 'on',
      receipt: false,
      addrList: {},
      // card:  null,
      orderNo: '',
      isShow: false,
      tid: '',
      appUrl: '',
      deviceType: '',
      addr: false,
      reqToken: '',
      params: {},
      optInfo: true,
      isClicked: true,
      optList: {
        option: {
          option_title: 'test',
          options: [
            {
              add_price: 0,
              id: 1,
              name: '95',
              option_product_code: '10654088001',
            },
            {
              add_price: 0,
              id: 2,
              name: '100',
              option_product_code: '10654088002',
            },
            {
              add_price: 0,
              id: 3,
              name: '105',
              option_product_code: '10654088003',
            },
          ],
        },
      },
      addrOption: false,
      paymentOption: false,
      defaultAddr: {},
      acntNo: '',
      userNo: '',
      usePoint: '',
      parameter: {},
      nativeFocus: true,
      cardIdx: 0,
      reqPage: 1,
      loading: true,
      payment: 'bbarshop',
      isOneClick: true,
      payResult: [],
      clickBtnYn: false,
      swiperOptions: {
        // slidesOffsetBefore: 40,
        // slidesOffsetAfter: 40,
        initialSlide: 1,
      },
      paymentView: false,
      btnClick: false,
      deleteData: false,
      changePersonalCode: true,
    };
  },

  computed: {
    ...mapGetters(['isLogin', 'getUserPoint', 'getDeviceType', 'getDeviceName', 'getTermType', 'getSessionId', 'getUserCel']),
    userPoint() {
      if (this.tpStatus === 'on' && this.$store.state.bbarshop.storeDetail.in_app_pay_options.pay_types.includes(4)) {
        let tpValue = parseInt(this.$store.state.tvpoint.pointBalance.toString().replace(/[^\d]/g, ''), 10); // 입력된 값을 숫자만 남기도록 필터링
        tpValue = tpValue < 100 ? 0 : Math.floor(tpValue / 100) * 100; // 100 단위로 내림하여 변경, 100단위 이하인 경우 0으로 설정
        let allPrice = Number(this.$store.state.bbarshop.userSelect.price);
        // let allPrice =
        // 	Number(this.$store.state.bbarshop.userSelect.price) + Number(this.$store.state.bbarshop.productData.shipping.shipping_cost);
        let priceValue = parseInt(allPrice.toString().replace(/[^\d]/g, ''), 10); // 입력된 값을 숫자만 남기도록 필터링
        priceValue = priceValue < 100 ? 0 : Math.floor(priceValue / 100) * 100;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.usePoint = tpValue > priceValue ? priceValue : tpValue;
        return tpValue > priceValue ? priceValue : tpValue;
      } else {
        return '';
      }
    },
    cardName() {
      return cardCode => {
        let result = '';
        switch (cardCode) {
          case '2088':
            result = '신한';
            break;
          case '1011':
            result = '농협';
            break;
          case '2050':
            result = '비씨';
            break;
          case '2004':
            result = '국민';
            break;
          case '2081':
            result = '하나';
            break;
          case '2066':
            result = '삼성';
            break;
          case '2055':
            result = '롯데';
            break;
          case '2077':
            result = '현대';
            break;
        }

        return result;
      };
    },
    usablePoint() {
      this.$utils.printLog('computed usablePoint!!!!!');
      if (this.userPoint > this.$store.state.bbarshop.userSelect.price) {
        return this.userPoint;
      } else {
        return this.$store.state.bbarshop.userSelect.price;
      }
    },
    paymentPrice() {
      let paymentPrice;
      if (this.tpStatus === 'on') {
        if (this.userPoint > this.$store.state.bbarshop.userSelect.price) {
          paymentPrice = this.$store.state.bbarshop.productData.shipping.shipping_cost;
          // if (this.userPoint > this.$store.state.bbarshop.userSelect.price + this.$store.state.bbarshop.productData.shipping.shipping_cost) {
          // 	paymentPrice = 0;
        } else {
          paymentPrice =
            this.$store.state.bbarshop.userSelect.price + this.$store.state.bbarshop.productData.shipping.shipping_cost - this.userPoint;
        }
      } else {
        paymentPrice = this.$store.state.bbarshop.userSelect.price + this.$store.state.bbarshop.productData.shipping.shipping_cost;
      }
      return paymentPrice;
    },
    card() {
      return this.$store.state.orderPayment.payCardList[this.cardIdx];
    },
    selectCardName() {
      let name = '';
      if (this.cardIdx === this.$store.state.orderPayment.payCardList.length) {
        // 신규카드
        // name =
        // 	this.$store.state.orderPayment.selectCard.cardCode === null
        // 		? '결제수단 등록이 필요합니다'
        // 		: // : this.$store.state.orderPayment.selectCard.cardName;
        name = '결제수단 등록이 필요합니다';
      } else if (this.cardIdx === this.$store.state.orderPayment.payCardList.cardList.length + 1) {
        // 신규계좌
        name = '신규계좌';
      }
      return name;
    },
    isAddrEmpty() {
      return this.$commons.isNull(this.addrList.list);
    },
    hasDefaultAddr() {
      return Object.keys(this.defaultAddr).length > 0;
    },
    showAddrMore() {
      return !this.$refs.addrMore.classList.contains('off');
    },
    isUseTVpoint() {
      return this.$store.state.bbarshop.storeDetail.in_app_pay_options.pay_types.includes(4);
    },
    shippingDate() {
      return selectedDate => {
        const date = new Date(selectedDate);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}${month}${day}`;
      };
    },
    personalCode() {
      return this.$store.state.bbarshop.personalCode;
    },
  },

  watch: {
    nativeFocus(hasFocus) {
      this.$utils.printLog('[watch] nativeFocusHandler call', hasFocus);

      const url = urlConfig.APPCARD_MODULE_BASE_URL;

      if (hasFocus) {
        document.getElementById('appCard').contentWindow.postMessage(
          JSON.stringify({
            message: 'focus',
          }),
          // "http://192.168.0.242:8080",
          url,
        );
        // window.postMessage(JSON.stringify({
        //     message : 'focus',
        //   }),'*')
      } else {
        document.getElementById('appCard').contentWindow.postMessage(
          JSON.stringify({
            message: 'blur',
          }),
          // "http://192.168.0.242:8080",
          url,
        );
      }
    },
    defaultAddr(newVal) {
      if (newVal && this.$store.state.tvpoint.userName !== newVal.recipientName) {
        // 주문자와 배송 받을 사람이 다를때, 통관부호 빈값으로
        this.deleteData = true;
        this.btnActivate = false;
        this.changePersonalCode = false;
      } else {
        // 같을때, 저장되어 있는 통관부호 보여주기
        this.$store.dispatch('personalCodeGet').then(data => {
          if (!this.$commons.isNull(data)) {
            this.$store.commit('setPersonalCode', data.personal_identification.pi);
          }
        });
        this.deleteData = false;
        this.changePersonalCode = true;
      }
    },
  },

  created() {
    $store.commit('setOrderPath', ''); // 사용자가 뒤로가기로 주문서로 돌아올 경우 대비해 초기화 mjkim
    this.$commons.printLog('[빠른구매] orderPath >>', this.$store.state.bbarshop.orderPath);
    this.$store.commit('setClickCount', 0); //router.go() 초기화
    this.status = 'off';

    if (this.getDeviceName === 'IOS' && this.getDeviceType === 2) {
      this.deviceType = '1';
    } else if (this.getDeviceName === 'IOS' && this.getDeviceType === 3) {
      this.deviceType = '2';
    } else if (this.getDeviceName === 'ANDROID' && this.getDeviceType === 2) {
      this.deviceType = '3';
    } else if (this.getDeviceName === 'ANDROID' && this.getDeviceType === 1) {
      this.deviceType = '4';
    }

    this.$utils.sequenceEx([
      this.fitGetAddr,
      this.getUserUsablePoint,
      function () {
        bus.$emit('end:loading');
      },
    ]);
  },

  mounted() {
    window.nativeFocusHandler = this.nativeFocusHandler;
  },

  methods: {
    btnActive(data) {
      this.btnActivate = data;
      this.changePersonalCode = data;
      this.deleteData = false;
    },
    clickEvent(sort, idx, item) {
      switch (sort) {
        case 'tpStatus': {
          if (this.tpStatus === 'on') {
            this.tpStatus = 'off';
            //	tvpoint 미사용
            this.usePoint = '';
          } else if (this.tpStatus === 'off') {
            this.tpStatus = 'on';
            //	tvpoint 사용
            if (
              this.userPoint >
              this.$store.state.bbarshop.userSelect.price + this.$store.state.bbarshop.productData.shipping.shipping_cost
            ) {
              this.usePoint = this.$store.state.bbarshop.userSelect.price + this.$store.state.bbarshop.productData.shipping.shipping_cost;
            } else {
              this.usePoint = this.userPoint;
            }
          }
          break;
        }
        case 'check': {
          this.status = 'off';
          // this.$refs.option.classList.add('clicked');
          break;
        }
        case 'fastPayDetail': {
          this.$store.commit('alertPopup', { popupType: 'NeedToLoginPopup' });
          break;
        }
        case 'store': {
          this.$refs.store.classList.toggle('clicked');
          document.querySelector('.more-btn').classList.toggle('clicked');

          break;
        }
        case 'closePopup': {
          this.$emit('closePopup');
          break;
        }
        case 'receipt': {
          if (this.receipt) {
            this.receipt = false;
          } else {
            this.receipt = true;
          }
          break;
        }
        case 'addrChange': {
          this.addrOption = !this.addrOption;
          if (this.paymentOption && this.addrOption) {
            this.paymentOption = false;
          }
          this.$refs.addrMore.classList.toggle('off');
          break;
        }
        case 'paymentOption': {
          this.paymentOption = !this.paymentOption;
          this.$nextTick(() => {
            if (this.paymentOption) {
              // 스와이퍼에서 해당 슬라이드로 이동하도록 구현. 카드번호 cardIdx
              this.$refs.bannerSwiper.goSlide(this.cardIdx);
              // 토글이 닫혔다 열릴 경우 selectCard의 값이 cardCompanyList의 첫번째 값으로 대체 --> 앱카드 결제 시 카드 선택 로직임으로 주석처리
              // this.$utils.printLog('$store.state.orderPayment.selectCard', $store.state.orderPayment.selectCard);
            }
          });

          if (this.paymentOption && this.addrOption) {
            this.addrOption = false;
            this.$refs.addrMore.classList.toggle('off');
          }
          this.$refs.option.classList.toggle('clicked');
          break;
        }
        case 'addrRegister': {
          this.$store.commit('setFastPayPopup', true); //배송지 등록 후 빠른 결제 팝업 바로 뜨도록
          this.$router.push({ name: 'NewAddress', query: { type: '등록' } });
          break;
        }
        case 'pay': {
          if (this.$commons.isNull(this.addrList.list)) {
            this.$utils.printLog('버튼 비활성화');
            this.$store.commit('alertPopup', {
              popupType: 'ErrorPopup',
              popupContent: '배송지 등록이 필요합니다.',
              popupTitle: '알림',
            });
          } else {
            if (
              this.$store.state.bbarshop.productData.company_id == 69 &&
              (!this.btnActivate || !this.changePersonalCode || this.personalCode == '')
            ) {
              // 통관번호의 모든 항목이 체크 안되었을때, alert 팝업창 때문에 만듬 espark
              this.btnClick = !this.btnClick;
            } else {
              // 다른 결제 수단 선택 후 결제 버튼 클릭 시 alert 출력 mjkim
              let cardCode = this.$utils.isNull(this.$store.state.orderPayment.cardSelected.cardCompany)
                ? this.$store.state.orderPayment.cardSelected.cardCode
                : this.$store.state.orderPayment.cardSelected.cardCompany === 'newCard'
                ? this.$store.state.orderPayment.selectCard.cardCode
                : this.$store.state.orderPayment.cardSelected.cardCompany.cardCode === 'P088'
                ? 'P088'
                : null;
              if (!cardCode) {
                this.$store.commit('alertPopup', {
                  popupType: 'ErrorPopup',
                  popupContent: '결제수단을 선택해 주세요.',
                  popupTitle: '',
                });
              } else if (!this.clickBtnYn) {
                this.payProgress();
              }
            }
          }
          break;
        }
        case 'cardSelect': {
          this.cardIdx = idx;
          break;
        }
        case 'changeCard': {
          this.card = null;
          break;
        }
        case 'changeAddr': {
          this.addr = true;
          break;
        }
        case 'radioCheck': {
          this.defaultAddr = item;
          break;
        }
        case 'orderPaymentDetail': {
          this.$router.push('/bbarshop/orderPaymentDetail');
          break;
        }
        case 'exchangePoint': {
          this.$router.push('/tvpoint/exchange');
          break;
        }
        case 'changePersonalCode': {
          this.changePersonalCode = !this.changePersonalCode;
          if (this.defaultAddr && this.$store.state.tvpoint.userName !== this.defaultAddr.recipientName) {
            // 주문자와 배송 받을 사람이 다를때, 통관부호 빈값으로
            this.deleteData = true;
          } else {
            // 같을때, 저장되어 있는 통관부호 보여주기
            this.deleteData = false;
          }
          break;
        }
      }
    },
    onClickPayState(state) {
      this.isOneClick = state;
    },
    payProgress: _.debounce(
      async function () {
        this.$utils.printLog('payProgress');
        this.$utils.printLog('결제 상품', this.$store.state.bbarshop.userSelect);
        this.$utils.printLog('결제 카드', this.$store.state.orderPayment.cardSelected);
        this.$utils.printLog('pinYN', this.$store.state.orderPayment.cardSelected.pinYN);
        this.$store.state.orderPayment.cardSelected.pinAuthYN = this.isOneClick ? 'N' : 'Y';
        this.$utils.printLog('pinAuthYN', this.$store.state.orderPayment.cardSelected.pinAuthYN); // N 원클릭 결제, Y 비밀번호 결제
        $store.commit('setOrderPath', 'ProductDetail');

        if (this.$store.state.orderPayment.cardSelected.pinAuthYN === 'N') {
          this.$utils.printLog('----- 빠른 원클릭 결제 -----');

          this.$utils.printLog('빠른 원클릭 결제 mallCode : ', this.$store.state.bbarshop.mallCode);
          this.$utils.printLog('빠른 원클릭 결제 salesPlatform : ', this.$store.state.common.salesPlatform);
          this.$utils.printLog('빠른 원클릭 결제 bannerId : ', this.$store.state.common.bannerId);

          const _vue = this;
          bus.$emit('start:loading');
          this.$utils.sequenceEx([
            _vue.viewAccount,
            _vue.prepareTid,
            _vue.authFitUser,
            _vue.tvpaySmartConfirm,
            _vue.orderSave,
            function () {
              _vue.$commons.printLog('[빠른 원클릭 결제 완료] bannerId', _vue.$store.state.common.bannerId);
              bus.$emit('end:loading');
            },
          ]);
        } else if (this.$store.state.orderPayment.cardSelected.pinAuthYN === 'Y') {
          this.$utils.printLog('----- 빠른 비밀번호 결제 -----');

          this.$utils.printLog('빠른 비밀번호 결제 mallCode : ', this.$store.state.bbarshop.mallCode);
          this.$utils.printLog('빠른 비밀번호 결제 salesPlatform : ', this.$store.state.common.salesPlatform);
          this.$utils.printLog('빠른 비밀번호 결제 bannerId : ', this.$store.state.common.bannerId);

          this.tvpayGoPayment(); // TV페이 결제 메서드 mjkim
        }
      },
      300,
      false,
    ),
    tvpayGoPayment() {
      this.$utils.printLog('tvpayGoPayment');

      if (this.$store.state.orderPayment.cardSelected.pinYN === 'E') {
        // 비밀번호가 없을 경우 (pinYN E) : [3-4-5 Tv페이 간편 비밀번호 설정] 으로 이동
        const _vue = this;
        bus.$emit('start:loading');
        this.$utils.sequenceEx([
          _vue.viewAccount,
          _vue.prepareTid,
          _vue.saveParams, // 스마트컨펌 & 오더세이브 데이터 저장
          function () {
            bus.$emit('end:loading');
            _vue.$store.commit('setSetPwdState', 'pointSetPwd');
            _vue.$router.push('/tvpoint/purchase/tvpaySetPwd');
          },
        ]);
      } else if (this.$store.state.orderPayment.cardSelected.pinYN === 'Y') {
        // 비밀번호 있음
        const _vue = this;
        bus.$emit('start:loading');
        this.$utils.sequenceEx([
          _vue.viewAccount,
          _vue.prepareTid,
          _vue.saveParams,
          function () {
            bus.$emit('end:loading');
            _vue.$refs.keypad.keypadToggle();
          },
        ]);
      } else {
        // 비밀번호 5회이상 틀려 비밀번호 없을 경우 (pinYn N) : [2-2-9 인증방식 선택] 으로 이동
        const _vue = this;
        bus.$emit('start:loading');
        this.$utils.sequenceEx([
          _vue.viewAccount,
          _vue.prepareTid,
          _vue.saveParams, // 스마트컨펌 & 오더세이브 데이터 저장
          function () {
            bus.$emit('end:loading');
            // TV페이 메뉴에서 비밀번호 변경으로 진입 후 기존 비밀번호 생각나지 않을 때 or 기존 비밀번호 5회 오류 > pointSetPwd
            _vue.$store.commit('setSetPwdState', 'pointSetPwd');
            _vue.$router.push('/user/SelectAuthPage');
          },
        ]);
      }
    },
    goNextPwd(pwd) {
      const _vue = this;
      this.pwd = pwd;
      bus.$emit('start:loading');
      this.clickBtnYn = true;
      this.$utils.sequenceEx([
        this.authFitUser,
        _vue.tvpaySmartConfirm,
        this.orderSave,
        function () {
          _vue.$commons.printLog('[빠른 결제 완료] bannerId', _vue.$store.state.common.bannerId);
          bus.$emit('end:loading');
        },
      ]);
    },
    cardActvieIndex(idx) {
      // slideChange될때마다 emit으로 activeIndex 올리기
      this.cardIdx = idx;
    },
    productPrice(price) {
      this.price = price;
    },
    options(userOption) {
      this.userOption = userOption;
      this.optInfo = !this.optInfo;
    },
    confirm() {
      this.$store.commit('alertPopup', null);
    },
    nativeFocusHandler(hasFocus) {
      this.$utils.printLog('nativeFocusHandler call', hasFocus);
      this.nativeFocus = hasFocus;
    },
    appLogin() {
      this.isShow = true;
      window.addEventListener('message', this.handleMessage);
      // 이전버튼을 컨트롤 하기위해 히스토리를 추가
      window.history.pushState('forward', null, '');
      // history back 이벤트 핸들러 등록
      window.addEventListener('popstate', this.backBtnHandler);
    },
    // 팝업 호출 시 넣어놓은 history가 삭제 되면서 이벤트 발생 및 팝업종료 처리
    backBtnHandler() {
      this.isShow = false; // 주석해제해야함
      window.removeEventListener('message', this.handleMessage);
      // history back 이벤트 핸들러 삭제
      window.removeEventListener('popstate', this.backBtnHandler);
    },
    handleMessage(event) {
      const _vue = this;
      switch (event.data.resultCode) {
        case '0000': // 앱카드 인증 성공
          this.isShow = false;
          this.$utils.printLog('앱카드 인증 성공', event.data.resultCode);
          window.removeEventListener('message', this.handleMessage);
          // history back 이벤트 핸들러 삭제
          window.removeEventListener('popstate', this.backBtnHandler);

          this.$utils.printLog('event.data ====>', event.data);
          this.$utils.printLog('event.data.resultData.acntId ====>', event.data.resultData.acntId);
          this.$utils.printLog('event.data.resultData.userNo ====>', event.data.resultData.userNo);
          this.$utils.printLog('this.params.payType ====>', this.params.payType);

          if (this.params.payType === '1') {
            this.params.acntId = event.data.resultData.acntId;
            this.params.userNo = event.data.resultData.userNo;
          }
          //
          // this.$utils.printLog('acntNo ====>', this.acntNo);
          // this.$utils.printLog('mUserNo ====>', this.userNo);
          // this.$utils.printLog('getSetPwdState ====>', this.getSetPwdState);

          // this.callRequestAuthResult();
          this.smartConfirm();
          // bus.$emit('start:loading');

          break;

        case '0001':
          location.href = event.data.scheme;
          break;

        case '0002':
          this.isShow = false;
          window.removeEventListener('message', this.handleMessage);
          // history back 이벤트 핸들러 삭제
          window.removeEventListener('popstate', this.backBtnHandler);
          this.$utils.alertPopup(this, event.data.resultMessage, '알림');
          break;

        case '0003':
          this.$utils.printLog(event.data.resultCode);
          this.isShow = false;
          window.removeEventListener('message', this.handleMessage);
          // history back 이벤트 핸들러 삭제
          window.removeEventListener('popstate', this.backBtnHandler);
          break;
      }
    },
    close() {
      this.$router.go(-1); // history를 쌓지않기 위해 -1 로 이동함.
    },
    goPage(url) {
      this.$router.push(`/${url}`);
    },

    handleTouchmove(e) {
      this.$utils.printLog('[AlertPopup] test ! ');
      e.stopPropagation();
      e.preventDefault();
    },

    makeTid: _.debounce(function () {
      this.params = {
        storeId: this.$store.state.bbarshop.productData.code,
        amount: this.paymentPrice,
        goodsName: this.$store.state.bbarshop.productData.name,
        pgType:
          (this.$utils.isNull(this.$store.state.orderPayment.cardSelected.cardCompany) &&
            this.$store.state.orderPayment.cardSelected.cardCode !== 'P088') ||
          this.$store.state.orderPayment.cardSelected.cardCompany === 'newCard'
            ? fitMixin.methods.pgType(this.$store.state.bbarshop.productData.code)
            : (this.$utils.isNull(this.$store.state.orderPayment.cardSelected.cardCompany) &&
                this.$store.state.orderPayment.cardSelected.cardCode === 'P088') ||
              this.$store.state.orderPayment.cardSelected.cardCompany.cardCode === 'P088'
            ? '9'
            : null,
        instType: this.$store.state.orderPayment.selectDiscount.instType,
        installment: this.$store.state.orderPayment.selectDiscount.installment,
        siteCode: '',
        siteKey: '',
        cardCode: this.$utils.isNull(this.$store.state.orderPayment.cardSelected.cardCompany)
          ? this.$store.state.orderPayment.cardSelected.cardCode
          : this.$store.state.orderPayment.cardSelected.cardCompany === 'newCard'
          ? this.$store.state.orderPayment.selectCard.cardCode
          : this.$store.state.orderPayment.cardSelected.cardCompany.cardCode === 'P088'
          ? 'P088'
          : null,
        storeUserNo: this.$store.state.tvpoint.userNo,
        // storeUserNo: this.$store.state.orderPayment.tvPayUserNo,
        userName: this.$store.state.tvpoint.userName,
        payType: this.$utils.isNull(this.$store.state.orderPayment.cardSelected.cardCompany) ? '0' : '1',
        // acntId: this.params.payType === '0' ? this.$store.state.orderPayment.cardSelected.acntId : null,
        userNo: !this.$utils.isNull(this.$store.state.orderPayment.cardSelected.userNo)
          ? this.$store.state.orderPayment.cardSelected.userNo
          : '',
        // isOneclick: 'N',
      };

      this.params.acntId = this.params.payType === '0' ? this.$store.state.orderPayment.cardSelected.acntId : null;

      if (!this.params.cardCode) {
        this.$store.commit('alertPopup', {
          popupType: 'ErrorPopup',
          popupContent: '결제수단을 선택해 주세요.',
          popupTitle: '',
        });
      } else {
        this.$store.dispatch('tvPayMakeTid', this.params).then(data => {
          if (data.resultCode === '0000') {
            this.tid = data.tid;
            this.reqToken = data.reqToken;
            this.orderNo = data.orderNo;
            this.appUrl = `${urlConfig.APPCARD_MODULE_BASE_URL}
					?tid=${data.tid}&storeId=${this.params.storeId}
					&payType=${this.params.payType}&cardCode=${this.params.cardCode}
					&amount=${this.params.amount}&instType=${this.params.instType}
					&installment=${this.params.installment}&storeUserNo=${this.$store.state.tvpoint.userNo}
					&orderNo=${data.orderNo}&userNo=${this.params.userNo}
					&acntId=${this.params.acntId}&deviceType=${this.deviceType}&isFitYN=Y`;
            if (data.tid) this.appLogin();
          } else {
            this.$utils.printLog('makeTid fail');
            // this.$router.push('/bbarshop/orderErrorPage');
            bus.$emit('end:loading');
            this.$utils.errorMessage(this, data);
          }
        });
      }
    }, 500),
    smartConfirm() {
      bus.$emit('start:loading');
      this.parameter = {
        tid: this.tid,
        storeId: this.params.storeId,
        amount: this.params.amount,
        orderNo: this.orderNo,
        instType: this.params.instType,
        installment: this.params.installment,
        cardCode: this.params.cardCode,
        storeUserNo: this.$store.state.tvpoint.userNo,
        acntId: this.params.acntId,
        userNo: this.params.userNo,
        reqToken: this.reqToken,
        tvpoint: String(this.usePoint), //사용 결제 금액
        goodsName: this.$store.state.bbarshop.productData.name,
        termId: this.$store.state.tvpoint.userCel,
      };

      // if (this.tpStatus === 'on') {
      // 	this.parameter.tvpoint = String(this.usePoint);
      // } else {
      // 	this.parameter.tvpoint = '0';
      // }

      this.$store.dispatch('appCardSmartConfirm', this.parameter).then(data => {
        this.$utils.printLog('appCardSmartConfirm data.resultCode', data.resultCode);
        this.$utils.printLog('appCardSmartConfirm data', data);
        bus.$emit('end:loading');
        if (parseInt(data.resultCode) === 0) {
          // this.approvalNo = data.resultData.rApprovalNo;
          this.$utils.printLog('appCardSmartConfirm success');
          this.$utils.printLog('appCardSmartConfirm resultCode');
          this.orderSave(data.resultData);
        } else {
          this.$utils.printLog('appCardSmartConfirm fail');
          this.$router.push('/bbarshop/orderErrorPage');
        }
      });
    },
    async tvpaySmartConfirm() {
      const deferred = this.$utils.defer();
      this.$utils.printLog('tvpaySmartConfirm');

      let tvPayUserNo = this.$store.state.orderPayment.tvPayUserNo
        ? this.$store.state.orderPayment.tvPayUserNo
        : await this.$bridge.stringValue('mUserNo');

      this.parameter = {
        tid: this.tid,
        cardCode: this.$store.state.orderPayment.cardSelected.cardCode,
        amount: this.params.amount,
        instType: this.$store.state.orderPayment.selectDiscount.instType, // 1:일반, 2:무이자
        instalment: this.params.installment,
        acntNo: this.$store.state.orderPayment.cardSelected.acntNo,
        mUserNo: tvPayUserNo, // 간편결제 회원번호
        storeId: this.params.storeId, // 가맹점 구분값
        termId: this.$store.state.orderPayment.cardSelected.termId,
        paramTermIdYN: 'Y', // 계정리스트의 모바일 회원의 경우 Y
        tvpoint: String(this.usePoint), // 스마트 컨펌 찌를 때 포인트 사용금액
        goodsName: this.$store.state.bbarshop.productData.name,
        userCel: this.$store.state.tvpoint.userCel,
        jSessionId: this.$store.state.tvpoint.rpSession,
        orderNo: this.orderNo,
        userNo: this.$store.state.tvpoint.userNo,
      };
      if (this.$store.state.orderPayment.cardSelected.cardCode === '2050') {
        this.parameter.isNew = 'Y'; // BC카드 신인증 유무, BC카드로 결제시에만 보내줌
      }
      if (this.$store.state.orderPayment.cardSelected.pinAuthYN === 'Y') {
        // 원클릭 결제가 아닐 경우 parameter 추가
        this.parameter.tvPayAuthYn = 'N'; //인증타입. 휴대폰인증 Y, 비밀번호 N
      }

      this.$store.dispatch('tvpaySmartConfirm', this.parameter).then(data => {
        this.$utils.printLog('tvpaySmartConfirm data.resultCode', data.resultCode);
        this.$utils.printLog('tvpaySmartConfirm data', data);
        if (parseInt(data.resultCode) === 0) {
          this.$utils.printLog('tvpaySmartConfirm success');
          this.$utils.printLog('tvpaySmartConfirm resultCode');
          this.payResult = data.resultData;
          // this.orderSave(data.resultData);
          deferred.resolve();
        } else {
          this.$utils.printLog('tvpaySmartConfirm fail');
          this.$router.push('/bbarshop/orderErrorPage');
          deferred.reject([this, data]);
        }
      });
      // deferred.resolve();
      return deferred.promise;
    },
    async orderSave(data) {
      // const deferred = this.$utils.defer();
      bus.$emit('start:loading');
      this.$utils.printLog('orderSave');
      if (this.$utils.isNull(data)) {
        data = await this.payResult;
      }

      let tvPayUserNo = this.$store.state.orderPayment.tvPayUserNo
        ? this.$store.state.orderPayment.tvPayUserNo
        : await this.$bridge.stringValue('mUserNo');
      let params = {
        tid: this.tid,
        accountId: this.$store.state.orderPayment.cardSelected.acntNo,
        mallCode: this.$store.state.bbarshop.mallCode ? this.$store.state.bbarshop.mallCode : 'MOBILETP01',
        mallUserNo: this.$store.state.tvpoint.userNo,
        storeName: this.$store.state.bbarshop.productData.company,
        goodsId: this.$store.state.bbarshop.productData.id,
        goodsCode: this.$store.state.bbarshop.productData.product_code,
        goodsType: this.$store.state.bbarshop.productData.type.toString(),
        goodsName: this.$store.state.bbarshop.productData.name,
        goodsQty: this.$store.state.bbarshop.userSelect.userCount,
        goodsImgUrl: this.$store.state.bbarshop.productData.thumbnail_image,
        optionCode: this.$store.state.bbarshop.userSelect.options.option_product_code,
        optionName: this.$store.state.bbarshop.userSelect.options.name,
        giftCode: '',
        giftName: '',
        instType: this.$store.state.orderPayment.selectDiscount.instType,
        installmentPlan: this.params.installment,
        userName: this.$store.state.tvpoint.userName,
        userCelNo: this.$store.state.tvpoint.userCel,
        birthDate: this.$store.state.tvpoint.userBirthDate,
        tvpayUserNo: tvPayUserNo,
        storeId: this.params.storeId,
        companyId: this.$store.state.bbarshop.productData.company_id,
        // shippingHopeDate:
        // 	this.$store.state.bbarshop.productData.company_id == 52 && this.$store.state.bbarshop.selectedDate.shippingHopeDate
        // 		? this.$store.state.bbarshop.selectedDate.shippingHopeDate
        // 		: '',
        shippingHopeDate:
          this.$store.state.bbarshop.productData.company_id == 52 && this.$store.state.bbarshop.selectedDate
            ? this.shippingDate(this.$store.state.bbarshop.selectedDate)
            : '',

        orderAddrBasic: this.defaultAddr.orderAddrBasic,
        orderAddrDetail: this.defaultAddr.orderAddrDetail,
        recipientName: this.defaultAddr.recipientName,
        orderZipcode: this.defaultAddr.orderZipcode,
        orderCelNo: this.defaultAddr.orderCelNo,

        // pointAmount: this.usePoint, //포인트 사용금액
        pointAmount: this.parameter.tvpoint, // 스마트 컨펌 찌를 때 포인트 사용금액
        // pointUserNo: this.$store.state.tvpoint.userNo,
        pointUserNo: '',
        pointTransNo: '',
        // pointReserveState: '',
        // pointReserveExpDate: '',
        pointReserveExpAmount: '',
        pointReserveExpPercent: this.$store.state.bbarshop.productData.reward,
        // pointReserveStorePercent: '',
        // pointReserveGoodsTpPercent: '',
        // pointReserveGoodsPercent: '',
        // pointReserveFitCallPercent: '',
        // pointReserveDate: '',
        // pointReserveTransNo: '',
        // pointReserveAmount: '',
        //=================================
        // approvalNo: data.approvalNo, //필수
        // approvalNo: 'J23561890', //필수
        // cardNum: data.cardNum, //확인
        cardNum: this.$store.state.orderPayment.cardSelected.acntName,
        amount: this.params.amount,
        // amount: this.paymentPrice,  //실데이터
        // discount: '0',
        discount:
          this.$store.state.bbarshop.productData.price_original - this.$store.state.bbarshop.productData.price_discount
            ? (this.$store.state.bbarshop.productData.price_original - this.$store.state.bbarshop.productData.price_discount) *
              this.$store.state.bbarshop.userSelect.userCount
            : '0',
        orderNo: this.orderNo,
        shippingAmount: this.$store.state.bbarshop.productData.shipping.shipping_cost,

        storeCSTelNo: !this.$utils.isNull(this.$store.state.bbarshop.storeDetail.in_app_pay_options.callcenter_number)
          ? this.$store.state.bbarshop.storeDetail.in_app_pay_options.callcenter_number
          : '',
        storeTelNo: !this.$utils.isNull(this.$store.state.bbarshop.storeDetail)
          ? this.$store.state.bbarshop.storeDetail.in_app_pay_options.callcenter_mobile_number
          : '',
        sellerCode: !this.$utils.isNull(this.$store.state.common.salesPlatform) ? this.$store.state.common.salesPlatform : '', // 판매사 구분 코드 ( FIT00FIT01 : 핏콜라보 핏콜, FITSH00001 : 핏콜 제휴사 신한, BBARS000001 : 핏콜라보 빠숍)
        bannerId: !this.$utils.isNull(this.$store.state.common.bannerId) ? this.$store.state.common.bannerId : '', // 배너 ID
        category1: this.$store.state.bbarshop.category1,
        category2: this.$store.state.bbarshop.category2,
        category3: this.$store.state.bbarshop.category3,
        pccc: this.$store.state.bbarshop.productData.company_id == 69 ? this.personalCode : '',
      };

      if (parseInt(params.pointAmount) !== 0) {
        params.pointUserNo = this.$store.state.tvpoint.userNo;
        params.pointTransNo = data.pointTransNo;
        // 포인트 전액 결제 유무 값
        if (parseInt(params.amount) === 0) {
          params.pointAllPayYN = 'Y';
        } else {
          params.pointAllPayYN = 'N';
        }
      }
      // 포인트 단독 결제일 때는 data.approvalNo 없음
      if (parseInt(params.amount) !== 0) params.approvalNo = data.approvalNo;

      // params.pointReserveExpAmount =
      // 	this.params.amount * this.$store.state.bbarshop.productData.reward
      // 		? Math.floor((this.params.amount * this.$store.state.bbarshop.productData.reward) / 100).toString()
      // 		: '';
      let amountForReward =
        this.params.amount - this.$store.state.bbarshop.productData.shipping.shipping_cost < 0
          ? 0
          : this.params.amount - this.$store.state.bbarshop.productData.shipping.shipping_cost;
      params.pointReserveExpAmount =
        amountForReward * this.$store.state.bbarshop.productData.reward
          ? Math.floor((amountForReward * this.$store.state.bbarshop.productData.reward) / 100).toString()
          : '';

      this.$store.dispatch('orderSave', params).then(data => {
        this.resultCode = data.resultCode;
        if (data.resultCode === '0000') {
          this.$utils.printLog('orderSave 성공');
          this.$router.push({
            name: 'OrderComplete',
            query: { storeId: this.params.storeId, tid: this.tid },
          });
          bus.$emit('end:loading');
          // deferred.resolve();
        } else {
          this.$utils.printLog('orderSave 실패');
          this.$router.push('/bbarshop/orderErrorPage');
          bus.$emit('end:loading');
          // deferred.reject([this, data]);
        }
      });
      // return deferred.promise;
    },
    getUserUsablePoint() {
      // 보관함
      // jsessionid: 세션아이디
      // termType: M002002
      // deviceId: 전화번호
      const _vue = this;
      _vue.$utils.printLog('getUserUsablePoint');
      const data = _vue.$store.dispatch('getUserUsablePoint', {
        jsessionid: _vue.getSessionId,
        termType: _vue.getTermType,
        deviceId: _vue.getUserCel,
      });

      data.then(data => {
        this.$utils.printLog(data);
        if (parseInt(data.resultCode) === 0) {
          this.$utils.printLog('getUserUsablePoint success');
          this.$store.state.tvpoint.pointBalance = data.availableBalance * 1;
        } else {
          this.$utils.printLog('getUserUsablePoint fail');
        }
      });
    },
    // hasScrolled() {
    // 	if (!this.addrList) {
    // 		return;
    // 	}
    // 	this.$utils.printLog('스크롤');
    // },
    hasScrolled: _.debounce(
      function (event) {
        if (!this.addrList) {
          //
        } else {
          //스크롤이 하단에 위치하면 통신하도록 분기처리
          if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight) {
            this.$utils.printLog('hasScrolled');
            this.loadMore();
          }
        }
      },
      450,
      false,
    ),
    async loadMore() {
      //fitGetAddr
      if (this.loading) {
        this.$utils.printLog('loadMore');
        this.reqPage++;
        this.$utils.printLog('listCount', this.addrList);
        const params = {
          tvpayUserNo: this.$store.state.orderPayment.tvPayUserNo
            ? this.$store.state.orderPayment.tvPayUserNo
            : await this.$bridge.stringValue('mUserNo'),
          reqCount: 5,
          reqPage: this.reqPage,
        };
        this.$store.dispatch('fitGetAddr', params).then(data => {
          let addrList = this.addrList.list;
          for (let i in data.list) {
            addrList.push(data.list[i]);
          }
          this.addrList.listCount = data.listCount; // listCount의 값 update
          if (data.listCount != 5) {
            this.loading = false;
          }
        });
      }
    },
    // 선택한 카드의 계정 조회
    viewAccount() {
      const _vue = this;
      const deferred = this.$utils.defer();
      _vue.$utils.printLog('viewAccount');

      const params = {
        tvpaySubUrl: `/cert2/viewAccount.mv;jsessionid=${this.$store.state.tvpoint.rpSession}`,
        acntNo: _vue.$store.state.orderPayment.cardSelected.acntNo,
        userNo: _vue.$store.state.orderPayment.cardSelected.userNo,
        cardCode: _vue.$store.state.orderPayment.cardSelected.cardCode,
        termId: _vue.$store.state.tvpoint.termId,
        paramTermIdYN: 'Y',
        loginCheck: 'true',
      };
      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('viewAccount resultCode', data.resultCode);
        _vue.$utils.printLog('viewAccount data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('viewAccount resultCode');
          // selectAuthPage 휴대폰 번호 커밋 -mjkim
          const authDetail = {
            phoneNo: data.resultData.phoneNum,
          };
          _vue.$store.commit('setAuthDetail', authDetail);

          // 인증을 위한 데이터들 viewAccountDetail에 커밋 -mjkim 230628
          const detailData = {
            acntNo: _vue.$store.state.orderPayment.cardSelected.acntNo,
            userNo: _vue.$store.state.orderPayment.cardSelected.userNo,
            termId: _vue.$store.state.tvpoint.termId,
            isMobileUser: 'Y',
          };
          _vue.$store.commit('setViewAccountDetail', detailData);

          // 빠숍 결제일때만 bsPayment true로 커밋 (기본값 false) -mjkim
          _vue.$store.commit('setBsPayment', true);
          // this.$utils.printLog('bsPayment', _vue.$store.state.orderPayment.bsPayment);

          deferred.resolve();
        } else {
          _vue.$utils.printLog('viewAccount fail');
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    prepareTid() {
      const _vue = this;
      const deferred = this.$utils.defer();

      this.params = {
        storeId: _vue.$store.state.bbarshop.productData.code,
        buyerName: _vue.$store.state.tvpoint.userName,
        amount: _vue.paymentPrice,
        dcAmount: '',
        goodsName: _vue.$store.state.bbarshop.productData.name,
        installment: _vue.$store.state.orderPayment.selectDiscount.installment,
        instTypeList: _vue.$store.state.orderPayment.selectDiscount.instType,
        termId: _vue.$store.state.tvpoint.termId,
        termIp: '',
        pgType:
          (_vue.$utils.isNull(_vue.$store.state.orderPayment.cardSelected.cardCompany) &&
            _vue.$store.state.orderPayment.cardSelected.cardCode !== 'P088') ||
          _vue.$store.state.orderPayment.cardSelected.cardCompany === 'newCard'
            ? fitMixin.methods.pgType(_vue.$store.state.bbarshop.productData.code)
            : (_vue.$utils.isNull(_vue.$store.state.orderPayment.cardSelected.cardCompany) &&
                _vue.$store.state.orderPayment.cardSelected.cardCode === 'P088') ||
              _vue.$store.state.orderPayment.cardSelected.cardCompany.cardCode === 'P088'
            ? '9'
            : null,
      };

      _vue.$store.dispatch('prepareTid', this.params).then(data => {
        _vue.$utils.printLog('prepareTid data.resultCode', data.resultCode);
        _vue.$utils.printLog('prepareTid data', data);
        bus.$emit('end:loading');
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('prepareTid success');
          _vue.$utils.printLog('prepareTid resultCode');
          _vue.orderNo = data.orderNo;
          _vue.tid = data.tid;
          deferred.resolve();
        } else {
          _vue.$utils.printLog('prepareTid fail');
          deferred.reject([_vue, data]);
          // _vue.$utils.errorMessage(this, data);
        }
      });
      return deferred.promise;
    },
    authFitUser() {
      const _vue = this;
      const deferred = this.$utils.defer();

      _vue.$utils.printLog('authFitUser');
      this.clickBtnYn = true;
      const params = {
        tvpaySubUrl: `/cert2/authFitUser.mv;jsessionid=${this.$store.state.tvpoint.rpSession}`,
        acntNo: _vue.$store.state.orderPayment.cardSelected.acntNo,
        userNo: _vue.$store.state.orderPayment.cardSelected.userNo,
        termId: _vue.$store.state.orderPayment.cardSelected.termId,
        tid: _vue.tid,
        authType: 'PAY',
        storeId: _vue.params.storeId,
        pinAuthYN: this.$store.state.orderPayment.cardSelected.pinAuthYN === 'Y' ? 'Y' : 'N', // 검증 유무. 원클릭 결제 N, 티비페이 결제 Y
        loginCheck: 'true',
      };

      const data = _vue.$store.dispatch('callRelayRpServer', params);
      data.then(data => {
        _vue.$utils.printLog('authFitUser resultCode', data.resultCode);
        _vue.$utils.printLog('authFitUser data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('authFitUser success');
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
        // } else if (parseInt(data.resultCode) === -1999) {
        //   _vue.$utils.printLog('authFitUser fail');
        //   deferred.resolve();
        // } else {
        //   deferred.reject([_vue, data]);
        // }
      });
      return deferred.promise;
    },

    async saveParams() {
      this.$utils.printLog('saveParams');
      let tvPayUserNo = this.$store.state.orderPayment.tvPayUserNo
        ? this.$store.state.orderPayment.tvPayUserNo
        : await this.$bridge.stringValue('mUserNo');

      let smartConfirmData = {
        tid: this.tid,
        cardCode: this.$store.state.orderPayment.cardSelected.cardCode,
        amount: this.params.amount,
        instType: this.$store.state.orderPayment.selectDiscount.instType, // 1:일반, 2:무이자
        instalment: this.params.installment,
        acntNo: this.$store.state.orderPayment.cardSelected.acntNo,
        mUserNo: tvPayUserNo, // 간편결제 회원번호
        storeId: this.params.storeId, // 가맹점 구분값
        termId: this.$store.state.orderPayment.cardSelected.termId,
        paramTermIdYN: 'Y', // 계정리스트의 모바일 회원의 경우 Y
        tvpoint: String(this.usePoint), // 스마트 컨펌 찌를 때 포인트 사용금액
        goodsName: this.$store.state.bbarshop.productData.name,
        userCel: this.$store.state.tvpoint.userCel,
        jSessionId: this.$store.state.tvpoint.rpSession,
        orderNo: this.orderNo,
        userNo: this.$store.state.tvpoint.userNo,
      };
      if (this.$store.state.orderPayment.cardSelected.cardCode === '2050') {
        smartConfirmData.isNew = 'Y'; // BC카드 신인증 유무, BC카드로 결제시에만 보내줌
      }
      // 원클릭 결제가 아닐 경우 parameter 추가
      if (this.$store.state.orderPayment.cardSelected.pinAuthYN === 'Y') {
        smartConfirmData.tvPayAuthYn = 'N'; //인증타입. 휴대폰인증 Y, 비밀번호 N
      }
      this.$store.commit('smartConfirmParams', smartConfirmData);

      let orderSaveData = {
        accountId: this.$store.state.orderPayment.cardSelected.acntNo,
        amount: this.params.amount,
        // approvalNo 하단 // smartConfirm이후 생성되는 데이터
        birthDate: this.$store.state.tvpoint.userBirthDate,
        cardNum: smartConfirmData.cardCode,
        companyId: this.$store.state.bbarshop.productData.company_id,
        discount:
          this.$store.state.bbarshop.productData.price_original - this.$store.state.bbarshop.productData.price_discount
            ? (this.$store.state.bbarshop.productData.price_original - this.$store.state.bbarshop.productData.price_discount) *
              this.$store.state.bbarshop.userSelect.userCount
            : '0',
        giftCode: '',
        giftName: '',
        goodsCode: this.$store.state.bbarshop.productData.product_code,
        goodsId: this.$store.state.bbarshop.productData.id,
        goodsImgUrl: this.$store.state.bbarshop.productData.thumbnail_image,
        goodsName: this.$store.state.bbarshop.productData.name,
        goodsQty: this.$store.state.bbarshop.userSelect.userCount,
        goodsType: this.$store.state.bbarshop.productData.type.toString(),
        instType: smartConfirmData.instType,
        installmentPlan: smartConfirmData.instalment, // ???
        mallCode: this.$store.state.bbarshop.mallCode ? this.$store.state.bbarshop.mallCode : 'MOBILETP01',
        mallUserNo: this.$store.state.tvpoint.userNo,
        optionCode: this.$store.state.bbarshop.userSelect.options.option_product_code,
        optionName: this.$store.state.bbarshop.userSelect.options.name,
        orderAddrBasic: this.defaultAddr.orderAddrBasic,
        orderAddrDetail: this.defaultAddr.orderAddrDetail,
        orderCelNo: this.defaultAddr.orderCelNo,
        orderNo: this.orderNo,
        orderZipcode: this.defaultAddr.orderZipcode,
        // pointAllPayYN 하단
        pointAmount: String(this.usePoint), // 스마트 컨펌 찌를 때 포인트 사용금액
        pointReserveExpAmount: '',
        pointReserveExpPercent: this.$store.state.bbarshop.productData.reward,
        pointTransNo: '',
        pointUserNo: '',
        recipientName: this.defaultAddr.recipientName,
        shippingAmount: this.$store.state.bbarshop.productData.shipping.shipping_cost,
        storeCSTelNo: !this.$utils.isNull(this.$store.state.bbarshop.storeDetail.in_app_pay_options.callcenter_number)
          ? this.$store.state.bbarshop.storeDetail.in_app_pay_options.callcenter_number
          : '',
        storeId: smartConfirmData.storeId,
        storeName: this.$store.state.bbarshop.productData.company, //ㅇㅇㅇ
        storeTelNo: !this.$utils.isNull(this.$store.state.bbarshop.storeDetail)
          ? this.$store.state.bbarshop.storeDetail.in_app_pay_options.callcenter_mobile_number
          : '',
        tid: this.tid,
        tvpayUserNo: tvPayUserNo,
        userCelNo: this.$store.state.tvpoint.userCel,
        userName: this.$store.state.tvpoint.userName,
        // shippingHopeDate:
        // 	this.$store.state.bbarshop.productData.company_id == 52 && this.$store.state.bbarshop.selectedDate.shippingHopeDate
        // 		? this.$store.state.bbarshop.selectedDate.shippingHopeDate
        // 		: '',
        shippingHopeDate:
          this.$store.state.bbarshop.productData.company_id == 52 && this.$store.state.bbarshop.selectedDate
            ? this.shippingDate(this.$store.state.bbarshop.selectedDate)
            : '',
        sellerCode: !this.$utils.isNull(this.$store.state.common.salesPlatform) ? this.$store.state.common.salesPlatform : '', // 판매사 구분 코드 ( FIT00FIT01 : 핏콜라보 핏콜, FITSH00001 : 핏콜 제휴사 신한, BBARS000001 : 핏콜라보 빠숍)
        bannerId: !this.$utils.isNull(this.$store.state.common.bannerId) ? this.$store.state.common.bannerId : '', // 배너 ID
        category1: this.$store.state.bbarshop.category1,
        category2: this.$store.state.bbarshop.category2,
        category3: this.$store.state.bbarshop.category3,
        pccc: this.$store.state.bbarshop.productData.company_id == 69 ? this.personalCode : '',
      };
      this.$store.commit('orderSaveParams', orderSaveData);
    },
    insSessionData() {
      const _vue = this;
      const deferred = _vue.$utils.defer();
      this.$store
        .dispatch('insSessionData', {
          termType: this.getTermType,
          clientType: this.getDeviceType,
        })
        .then(res => {
          if (parseInt(res.resultCode) === 0) {
            this.$store.commit('ssKey', res.resultData.hmbDataKey);
            deferred.resolve();
          } else {
            deferred.reject([_vue, res]);
          }
        });
      return deferred.promise;
    },
    getSessionData() {
      const _vue = this;
      const deferred = _vue.$utils.defer();
      this.$store.dispatch('getSessionData', { dataKey: this.$store.getters.ssKey }).then(res => {
        if (parseInt(res.resultCode) === 0) {
          this.$store.commit('userDetailInfo', res.resultData);
          _vue.$store.commit('setRpSession', res.resultData.jSession);
          this.jSession = res.resultData.jSession;
          deferred.resolve();
        } else {
          deferred.reject([_vue, res]);
        }
      });
      return deferred.promise;
    },
    getFitAccountList() {
      this.$utils.printLog('getFitAccountList');
      let params = {
        tvpaySubUrl: `api/getFitAccountList.mv;jsessionid=${this.$store.state.tvpoint.rpSession}`,
        loginCheck: 'true',
      };
      this.$store.dispatch('callRelayRpServer', params).then(data => {
        if (data.resultCode === '0000') {
          const list = data.resultData.list.filter(data => data.isMobileUser === 'Y');
          $utils.printLog('list : ', list);
          $store.commit('setPayCardList', list);
          if (data.resultData.list && data.resultData.list !== 0) {
            $store.state.orderPayment.cardSelected = $store.state.orderPayment.payCardList[0];
            $store.commit('setSelectDiscount', { instType: '1', name: '일시불', installment: '00' });
          } else if (data.resultData.list.length === 0) {
            $store.state.orderPayment.cardSelected.cardCompany = 'newCard';
          }
        } else {
          $utils.printLog();
          alert(data.resultMessage);
        }
      });
    },
    async fitGetAddr() {
      //배송지
      let tvPayUserNo = this.$store.state.orderPayment.tvPayUserNo
        ? this.$store.state.orderPayment.tvPayUserNo
        : await this.$bridge.stringValue('mUserNo');
      const params = {
        tvpayUserNo: tvPayUserNo,
        reqCount: 5,
        reqPage: 1,
      };
      this.$store.dispatch('fitGetAddr', params).then(data => {
        //기본배송지
        if (data && data.list.filter(e => e.defaultAddrYN === 'Y').length > 0) {
          this.defaultAddr = data.list.filter(e => e.defaultAddrYN === 'Y')[0];
        } else if (data && data.list.filter(e => e.defaultAddrYN === 'Y').length === 0) {
          this.defaultAddr = data.list[0];
        }
        //등록된 배송지
        this.addrList = data;
      });
    },
    hasOrderPath(state) {
      if (!state) {
        this.$store.commit('setOrderPath', '');
        this.$commons.printLog('[빠른구매 결제 close] orderPath >>', this.$store.state.bbarshop.orderPath);
        this.clickBtnYn = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.column__title {
  margin: 40px;

  #{&}__arrow {
    transition: transform 300ms ease-in-out;
    margin-left: 24px;

    &.clicked {
      transition: transform 300ms ease-in-out;
      transform: rotate(180deg);
    }
  }

  #{&}__more {
    & {
      display: block;
    }

    &.off {
      display: none;
    }
  }
}

.column__title__arrow {
  transition: transform 300ms ease-in-out;
  margin-left: 24px;

  &.clicked {
    transition: transform 300ms ease-in-out;
    transform: rotate(180deg);
  }
}

.column__addr {
  // margin: 40px;

  // #{&}__arrow {
  // 	transition: transform 300ms ease-in-out;
  // 	margin-left: 24px;

  // 	&.clicked {
  // 		transition: transform 300ms ease-in-out;
  // 		transform: rotate(180deg);
  // 	}
  // }

  #{&}__more {
    & {
      display: block;
      height: 362px;
      background-color: #f2f3f5;
      overflow: scroll;
    }

    &.off {
      display: none;
    }
  }
}

.options-wrap {
  width: auto;
  height: 80px;
  padding: 0 32px;
  background-color: #f2f3f5;
}
.options {
  font-family: NotoSansCJKkr;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -1.12px;
  // text-align: left;
  color: #2b2d2f;
}
.column__title__more {
  overflow: scroll;
  width: 100%;
  height: 254px;
  border: solid 1px #dadcde;
}
.check-all:checked + label {
  margin-right: 16px;
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  // background: url('~@/assets/img/tvpoint/check_circle_on.svg') no-repeat;
  background-image: url('~@/assets/img/common/btn_radio_on.svg');
  box-sizing: border-box;
}

.check-all {
  display: none;
  float: left;
  box-sizing: border-box;
}

.check-all + label {
  margin-right: 16px;
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  // background: url('~@/assets/img/tvpoint/check_circle_off.svg') no-repeat;
  background-image: url('~@/assets/img/common/btn_radio_off.svg');
  box-sizing: border-box;
}
.option2 {
  display: flex;
  justify-content: space-between;
}
.price {
  font-family: Pretendard;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -2px;
  text-align: left;
  color: #222;
}

.numberInput {
  width: 136px;
  height: 64px;
  border-radius: 8px;
  border: solid 1px #dadcde;
  font-family: Pretendard;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #222;
  margin: 0 8px;
}
.btn-wrap {
  display: flex;
  justify-content: space-between;
}
.btn {
  width: 70px;
  height: 48px;
  border-radius: 4px;
  border: solid 2px #dadcde;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 2.67;
  letter-spacing: -1.2px;
  color: #2b2d2f;
}
.btn-change {
  box-sizing: border-box;
  width: 79px;
  height: 48px;
  border-radius: 4px;
  border: solid 2px #dadcde;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 2.67;
  letter-spacing: -1.2px;
  color: #2b2d2f;
}
.btn {
  width: 112px;
  height: 48px;
  border-radius: 4px;
  border: solid 2px #dadcde;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  line-height: 2.67;
  letter-spacing: -1.2px;
  color: #2b2d2f;
}
.btn-bottom {
  width: 100%;
  height: 100px;
  background-color: #2b2d2f;
  font-size: 32px;
  line-height: 100px;
  letter-spacing: -1.6px;
  text-align: center;
  color: white;
}
.column__title {
  margin: 30px 40px 0;

  #{&}__arrow {
    transition: transform 300ms ease-in-out;
    margin-left: 24px;

    &.clicked {
      transition: transform 300ms ease-in-out;
      transform: rotate(180deg);
    }
  }
}
.deliveryFee {
  width: auto;
  height: auto;
  padding: 12px;
  border-radius: 4px;
  background-color: #f2f3f5;
  font-family: Pretendard !important;
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: -0.8px;
  color: #76787a;
  text-align: left;
}

.pop-layer {
  vertical-align: middle;
  width: 560px;
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 12.5px 12.5px 0 0;
}

.pop-title {
  display: inline-block;
  margin: 40px 40px 0 40px;
  height: 32px;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.6px;
  text-align: left;
  width: 100%;
  color: #343534;
}
.layer-wrap {
  // display: none;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  // margin-right: -.25em;
}
.pop-layer {
  display: inline-block;
  vertical-align: middle;
  // width: 300px;
  width: 560px;
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 12.5px 12.5px 0 0;
}
.contents-container {
  padding: 35px;
}
.txt_contents {
  width: 100%;
  height: 160px;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.btn_confirm {
  display: inline-block;
  width: 100%;
  height: 90px;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -1.5px;
  text-align: right;
  border-radius: 16px;
  background-color: #ffffff;

  color: #648cff;
}

.btn_confirm span {
  display: inline-block;
  margin-right: 40px;
}
.discount-box {
  width: auto;
  height: 36px;
  padding: 5px 17px;
  border-radius: 21px;
  border: solid 2px #dadcde;
  background-color: #f2f3f5;
}
.arr-btn {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  position: absolute;
  border-radius: 12px;
  border: solid 8px #2b2d2f;
  box-sizing: border-box;
  padding: 17.5px;
}
</style>
