import axios from 'axios';
import $store from '@/store';
import $utils from '@/utils/tvPointcommon';
import $commons from '@/utils/commons';
import urlConfig from '@/config/urlConfig';
import bus from '@/utils/bus';
import $bridge from '@/utils/FTBridgeShared';

const axiosInterceptor = axios;

axiosInterceptor.interceptors.request.use(
  async config => {
    // bus.$emit('start:loading');
    const login = $store.state.tvpoint.isLogin;

    //header에 authentication 적용.
    if (login && (config.url.indexOf(urlConfig.PAI_BASE_URL) > -1 || config.url.indexOf(urlConfig.BS_BASE_URL) > -1)) {
      //pai 로그인 상태
      config.url.indexOf('/session/renew') > -1 ? await loginAuth(config, 'renewal_token') : await loginAuth(config, 'access_token');
      analytics(config, 'login');
      // console.log('url : ', config.url);
      // console.log('axiosInterceptor analyticsinfo : ', config.headers['analyticsinfo']);
    } else if (!login && config.url.indexOf(urlConfig.BS_BASE_URL) > -1) {
      // bs 비로그인 상태
      analytics(config, 'notLogin');
      // console.log('url : ', config.url);
      // console.log('axiosInterceptor analyticsinfo : ', config.headers['analyticsinfo']);
    } else if (!login && config.url.indexOf(urlConfig.PAI_BASE_URL) > -1) {
      //pai 비로그인 상태
      if (config.url === `${urlConfig.PAI_BASE_URL}logout`) {
        await loginAuth(config, 'access_token');
      } else if (config.url.includes('guest')) {
        $commons.printLog(' config.url.includes(guest) url : ', config.url);
      } else {
        $commons.printLog('url : ', config.url);
        config.url = config.url.replace(urlConfig.PAI_BASE_URL, `${urlConfig.PAI_BASE_URL}guest/`);
        $commons.printLog('url : ', config.url);
      }
      analytics(config, 'notLogin');
    } else if (config.url.indexOf(urlConfig.FIT_BASE_URL) > -1) {
      //fit login 상태
      await loginAuth(config, 'loginToken');
      if (config.url.includes('/fit/setAlarmData')) {
        // $commons.printLog('setAlarmData header에 통계정보');
        analytics(config, 'login');
      }
    } else if (config.url.indexOf(urlConfig.FITSCH_BASE_URL) > -1) {
      login ? analytics(config, 'login') : analytics(config, 'notLogin');
    } else if (config.url.indexOf(urlConfig.FCM_BASE_URL) > -1) {
      config.headers.Authorization =
        'key=AAAAWzGeCfY:APA91bGQqDrFO4fdyLiCBYtDhvvoX8bfi2BBAHlxKiSK-rgOlHIl_RQtF_HiDjvA5oxwL6HaVHRvbuHgoHkRxFUCcUBqvtzmLKSh6jhzLT5O67EuPBpGTamrH8U3YiUKzpBL_6OYy-T5';
    }
    // else if (config.url === 'https://benefit.buzzvil.com/api/total-reward') {
    // 	config.headers['Buzz-App-ID'] = '28471428331506';
    // }
    return config;
  },
  error => {
    bus.$emit('end:loading');
    return Promise.reject(error);
  },
);

axiosInterceptor.interceptors.response.use(
  response => {
    if (!$utils.isNull(response.data.jsessionId) && response.data.jsessionId !== $store.state.tvpoint.jsessionId) {
      $store.commit('setSessionId', response.data.jsessionId);
    }
    return transResult(response);
  },
  async error => {
    const originalRequest = error.config;
    //renewal 토큰이 만료 되면 다시 로그인을 하는데 로그인을 할때 originalRequest를 다시 axios 태우는데 그게 renewal 토큰임. 실제로는 renewalToken 전의 api 를 태워야 함.
    let beforeRequest;

    // jhkim: vue3:
    // $commons.printLog('☆ error response config : ', error.config.url);
    // $commons.printLog('☆ error response : ', JSON.stringify(error.response));
    $commons.printLog('☆ error response : ', error);

    if (error.response.status === 401 && error.config.url.indexOf('/session/renew') > -1) {
      //access_token 만료되서 renewToken 실행 후 renewal_token도 하루가 지나서 만료 될 경우 다시 로그인
      const params = {
        device_id: !$commons.isNull($store.state.common.saveDeviceId) ? $store.state.common.saveDeviceId : null,
        device_type: $store.state.common.deviceType,
        fit_token: await $bridge.shared().stringValue('loginToken'),
      };

      // if ($store.state.common.deviceType === 1) {
      // const curVersion = window.Android.checkCurrentVersion().split('.');
      // const appVersion = parseInt(`${curVersion[0]}${curVersion[1]}${curVersion[2]}`);

      let fcmToken = $store.state.common.token;

      if ($commons.isNull(fcmToken)) {
        fcmToken = await $bridge.shared().stringValue('requestToken');
        $store.commit('saveToken', fcmToken);
        $commons.printLog('☆ axiosInterceptors paiLogin  requestToken fcmToken : ', fcmToken);
        $commons.printLog('☆ axiosInterceptors paiLogin  $store.state.common.token  : ', $store.state.common.token);
      }
      params.fcm_token = fcmToken;
      // }

      const res = await $store.dispatch('paiLogin', params);
      $commons.printLog('☆ axiosInterceptors paiLogin Success');
      const { access_token, renewal_token } = res;
      originalRequest.headers.Authorization = access_token;
      // if ($store.state.common.deviceType === 1) {
      await $commons.subscribeFCM();
      // }
      return axios(originalRequest);
    } else if (error.response.status === 401) {
      //파이로그인 실패 시 핏 서비스 이용을 위해 정상 동작.
      //파이로그인 실패 후 메인 페이지 진입 시 파이서버 401 에러. 이때 access_token이 없으므로 renew 요청 해도 에러.
      //access_token 값이 있을 경우에만 renew 요청 하도록 수정. jslim(22-12-23)
      //access_token 만료되서 renewal_token 으로 renewToken 실행

      if (error.config.url.indexOf('/iid') > -1) {
        $commons.printLog('☆ error.config.url : ', error.config.url);
      } else {
        const accessToken = await $bridge.shared().stringValue('access_token');
        if (!$commons.isNull(accessToken)) {
          const res = await $store.dispatch('renewToken');
          const { access_token, renewal_token } = res;
          originalRequest.headers.Authorization = access_token;
          return axios(originalRequest);
        } else {
          const params = {
            device_id: !$commons.isNull($store.state.common.saveDeviceId) ? $store.state.common.saveDeviceId : null,
            device_type: $store.state.common.deviceType,
            fit_token: await $bridge.shared().stringValue('loginToken'),
          };
          // if ($store.state.common.deviceType === 1) {
          // const curVersion = $bridge.shared().versionName.split('.');
          // const appVersion = parseInt(`${curVersion[0]}${curVersion[1]}${curVersion[2]}`);

          let fcmToken = $store.state.common.token;

          if ($commons.isNull(fcmToken)) {
            fcmToken = await $bridge.shared().stringValue('requestToken');
            $store.commit('saveToken', fcmToken);
            $commons.printLog('☆ axiosInterceptors paiLogin  requestToken fcmToken : ', fcmToken);
            $commons.printLog('☆ axiosInterceptors paiLogin  $store.state.common.token  : ', $store.state.common.token);
          }
          params.fcm_token = fcmToken;
          // }

          const res = await $store.dispatch('paiLogin', params);
          // if ($store.state.common.deviceType === 1) {
          await $commons.subscribeFCM();
          // }
          $commons.printLog('☆ axiosInterceptors paiLogin Success');
          return axios(originalRequest);
        }
      }
      // const res = await $store.dispatch('renewToken');
      // const { access_token, renewal_token } = res;
      // originalRequest.headers.Authorization = access_token;
      // return axios(originalRequest);
    } else {
      $commons.printLog('☆ error response config 2 : ', error.config.url);
      bus.$emit('end:loading');
      $commons.printLog('☆ error.config.url : ', error.config.url);

      if (
        ((error.config.url === `${urlConfig.PAI_BASE_URL}guest/login` || error.config.url === `${urlConfig.PAI_BASE_URL}logout`) &&
          error.response.data.resultCode !== '0000') ||
        error.config.url.indexOf('firebasedynamiclinks.googleapis.com/v1/shortLinks') > -1
      ) {
        const data = error.response.data;
        $commons.printLog('☆ error response error.response.data : ', JSON.stringify(error.response.data));
        $commons.printLog('☆ error response error.response.data : ', data);
        $commons.printLog('☆ error response error.response.data typeof: ', typeof data);
        return { data };
      } else {
        return Promise.reject(error);
      }
      // return Promise.reject(error);
    }
  },
);

const transResult = response => {
  const data = response.data;
  if (data.result_code) {
    data.resultCode = data.result_code;
  }
  if (data.result_message) {
    data.resultMessage = data.result_message;
  }
  return { ...response, ...data };
};

const loginAuth = async (config, type) => {
  config.headers.Authorization = await $bridge.shared().stringValue(type);
};

//통계용
const analytics = (config, status) => {
  let entryPath = {};
  entryPath.source_id = !$commons.isNull($store.state.common.sourceId)
    ? $store.state.common.sourceId
    : !$commons.isNull($store.state.common.fitSourceId)
    ? $store.state.common.fitSourceId
    : $store.state.common.salesPlatform;
  entryPath.path_code = $store.state.bbarshop.mallCode;

  let banner = {};
  banner.component_id = $store.state.common.bannerId;
  banner.type = $store.state.common.componentType;
  entryPath.banner = banner;

  if (!$commons.isNull($store.state.common.alarmId)) {
    entryPath.alarm_id = $store.state.common.alarmId;
  }
  const info = {
    userInfo: {
      user_id: status === 'login' ? $store.state.tvpoint.userNo : '0000',
      gender: status === 'login' ? parseInt($store.state.tvpoint.genderCode) : 2,
      age: status === 'login' ? $commons.ageCalculator($store.state.tvpoint.userBirthDate) : 0,
    },
    device_type: $store.state.common.deviceType,
    user_agent: navigator.userAgent,
    version: $bridge.shared().versionName(),
    entry_path: entryPath,
  };

  // config.headers.common['analyticsinfo'] = JSON.stringify(info);
  // config.headers.common['analyticsadditionalinfo'] = JSON.stringify({});
  config.headers['analyticsinfo'] = JSON.stringify(info);
  config.headers['analyticsadditionalinfo'] = JSON.stringify({});
};

export default axiosInterceptor;
