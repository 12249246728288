const mutations = {
  setFitcolList(state, payload) {
    state.fitcolList = payload;
  },
  setCategoryIdx(state, payload) {
    state.categoryIdx = payload;
  },
};

export default mutations;
