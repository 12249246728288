<template>
  <div id="app">
    <transition name="fade">
      <div v-if="appView" id="img">
        <BannerInfo :get-banner-info-list="bannerInfo" />
      </div>
    </transition>
    <LoadingBar :is-loading="isLoading" />
    <AlertPopup />
    <AlertPopup2 />
    <SlidingPopup />
    <ExistingMemberPopup />
    <NonMemberPopup />
    <PointSavePopup />
    <ConfirmPopup />
    <SlidingPopUp :show="show" :pages="pages" :height="height" />
    <Index @on-init="onInit" />
  </div>
</template>

<script>
import Logger from '@/modules/ft-logger';

import bus from '@/utils/bus';
import LoadingBar from '@/components/common/LoadingBar';
import smoothscroll from 'smoothscroll-polyfill';
import Index from '@/views';
import AlertPopup from '@/components/tvpoint/popup/AlertPopup';
import SlidingPopup from '@/components/tvpoint/popup/SlidingPopup';
import ExistingMemberPopup from '@/components/tvpoint/popup/ExistingMemberPopup';
import NonMemberPopup from '@/components/tvpoint/popup/NonMemberPopup';
import AlertPopup2 from '@/components/tvpoint/popup/AlertPopup2';
import PointSavePopup from '@/components/tvpoint/popup/PointSavePopup';
import ConfirmPopup from '@/components/tvpoint/popup/ConfirmPopup';
import { fitMixin } from '@/utils/fitMixin';
import BannerInfo from '@/components/tvpoint/common/BannerInfo';
import SlidingPopUp from '@/components/common/popup/SlidingPopUp';

import $store from '@/store';

export default {
  name: 'App',
  components: {
    AlertPopup2,
    Index,
    LoadingBar,
    AlertPopup,
    SlidingPopup,
    ExistingMemberPopup,
    NonMemberPopup,
    PointSavePopup,
    ConfirmPopup,
    BannerInfo,
    SlidingPopUp,
  },

  mixins: [fitMixin],

  data() {
    return {
      isLoading: false,
      localTvInfo: {},
      appVersion: '',
      bannerInfo: {},
      linkValue: '',
      linkType: '',
    };
  },

  computed: {
    getRefreshYN() {
      return this.$store.state.common.refreshYN;
    },
    getInitFlag() {
      return this.$store.state.common.initFlag;
    },
    deviceType() {
      return this.$store.state.common.deviceType;
    },
    getToken() {
      return this.$store.state.common.token;
    },
    getSaveDeviceId() {
      return this.$store.state.common.saveDeviceId;
    },
    getExtractPhoneNumber() {
      return this.$store.state.common.extractPhoneNumber;
    },
    getAutoLoginCookie() {
      return this.$store.state.common.autoLoginCookie;
    },
    getIosPush() {
      return this.$store.state.common.iosPush;
    },
    androidData() {
      let result;
      if (this.$deviceType === 3) {
        result =
          this.getToken != null &&
          this.getSaveDeviceId != null &&
          this.getAutoLoginCookie != null &&
          !this.getIsFirst &&
          this.getIosPush !== 0;
      } else {
        result = this.getToken != null && this.getSaveDeviceId != null && this.getAutoLoginCookie != null && !this.getIsFirst;
      }
      return result;
    },
    // 앱 실행 시 로딩 이미지
    appView() {
      return this.$store.state.common.appView;
    },
    getIsFirst() {
      return this.$store.state.common.isFirst;
    },
    // show() {
    //   let result;
    //   if (this.$store.state.common.setSlidePopup.showSlide && !this.$commons.isNull(this.$store.state.common.alarmId)) {
    //     result = true;
    //   } else {
    //     result = false;
    //   }
    //   this.$commons.printLog('☆ App.vue show result : ', result);
    //   this.$commons.printLog('☆ App.vue show this.$store.state.common.alarmId : ', this.$commons.isNull(this.$store.state.common.alarmId));
    //   return result;
    //   // return this.$store.state.common.exitPopUp;
    // },
    // pages() {
    //   return this.$store.state.common.setSlidePopup.pages;
    // },
    // height() {
    //   return this.$store.state.common.setSlidePopup.height;
    // },
  },

  watch: {
    // ios 두번째 알람부터는 getIosPush 변화로 감지 230908 espark,mjkim
    getIosPush(newValue) {
      if ($store.state.common.deviceType === 3) {
        this.$utils.printLog('getIosPush', newValue);
        this.appPush(newValue);
      }
    },
    //안드로이드 전달 데이터가 모두 null이 아닐 때(androidData의 값이 모두 전달됐을 때)
    async androidData(newValue) {
      this.$utils.printLog('androidData', newValue);
      const isFirstLoad = await this.$bridge.isFirstLoad();
      this.$utils.printLog('androidData isFirstLoad : ', isFirstLoad);
      if (!isFirstLoad) {
        // ios 첫 진입시를 위해 isFirstLoad값이 false일때만 appPush 타도록 수정 mjkim
        this.appPush(newValue); // this.appPush(newValue); // 메서드 타는것으로 변경 230908 espark,mjkim
      }
    },
    //인증실패에 따른 세션초기화 팝업 셋팅
    sessionCheck() {
      this.dialogType = 'session';
      this.commonDialog = true;
    },
    $route(to, from) {
      if (to.path === '/initPage' || to.path === '/bbarshop' || to.path === '/menu') {
        this.$store.commit('setOrderCompletePath', to.path);
      }
    },
  },

  created() {
    this.$utils.bannerInfoList(this, 'MWBN26');

    if (this.$deviceType == 1) {
      window.Android.returnPermission();
    }

    // TODO: 안드로이드 네이티브에서 버전 체크 로직 반영

    // ISSUE: 안드로이드 로직 분석 필요
    window.saveFirstInit = this.saveFirstInit;
    window.permissionRecheck = this.permissionRecheck;
    window.noticeUpdate = this.noticeUpdate;
    window.backBtnHandler = this.backBtnHandler;
    window.exitPopup = this.exitPopup;
    window.savePermission = this.savePermission;
    window.permissionDeny = this.permissionDeny;
    // window.saveWifiState = this.saveWifiState;

    const userAgent = navigator.userAgent; //userAgent 값 얻기
    if (userAgent) {
      this.$store.commit('userAgent', userAgent);
    } else {
      this.$store.commit('userAgent', '');
    }

    let deviceName = '';
    if (userAgent.toLowerCase().match(/android/i)) {
      //안드로이드
      this.$store.commit('OS', 'android');
      deviceName = 'ANDROID';
    } else if (userAgent.toLowerCase().match(/iphone|macintosh|ipad|ipod/i)) {
      //IOS
      this.$store.commit('OS', 'ios');
      deviceName = 'IOS';
    } else {
      //아이폰, 안드로이드 외
      this.$store.commit('OS', 'other');
      deviceName = 'WEB';
    }
    this.$store.commit('setDeviceName', deviceName);

    this.$commons.printLog('☆ APP.vue created deviceName : ', deviceName);
    this.$commons.printLog('☆ APP.vue created deviceType : ', this.$deviceType);
    this.$commons.printLog('☆ APP.vue created url : ', window.location.href);
    this.$commons.printLog('☆ APP.vue created getIsFirst : ', this.getIsFirst);
    this.$commons.printLog('☆ APP.vue created getSaveDeviceId : ', this.getSaveDeviceId);
    this.$commons.printLog('☆ APP.vue created getToken : ', this.getToken);
    this.$commons.printLog('☆ APP.vue created getAutoLoginCookie : ', this.getAutoLoginCookie);
  },

  async mounted() {
    // 단말기에서 들어온 url의 경우, 티비포인트로 들어 올때 단말을 등록해야 함
    this.$commons.printLog('☆ App.vue mounted redirectPage : ', this.$route.query.redirectPage);
    this.$commons.printLog('☆ App.vue mounted tvTermId : ', this.$route.query.tvTermId);
    this.$commons.printLog('☆ App.vue mounted getIsFirst : ', this.getIsFirst);
    this.$commons.printLog('☆ APP.vue mounted getSaveDeviceId : ', this.getSaveDeviceId);
    this.$commons.printLog('☆ APP.vue mounted getToken : ', this.getToken);
    this.$commons.printLog('☆ APP.vue mounted getAutoLoginCookie : ', this.getAutoLoginCookie);

    // AOS 앱 2.2.0 경우 안드로이드 앱에서 https://www.fitpai.co.kr/TVPMV2/index.html 웹뷰 호출
    // www.fitcollabo.com으로 redirect 할 경우 앱에서 웹페이지로 호출하는 이슈로 redirect 해제
    // 다른 서비스는 정상 작동하나 기프티콘 결제 이슈(FIT_API_V1 서버에서 기프티콘 서버 연동 후 www.fitcollabo.com 도메인으로 호출)
    // AOS 앱 2.2.0은 강제 업데이트 적용하고, 즐겨잧기나 직접 URL 작성하여 진입할 경우 기프티콘 에러 방지를 위해
    // 앱 설치 유도 안내 팝업 호출
    // 2023-11-23, jslim
    let domain = window.location.href.includes('https://www.fitpai.co.kr');
    this.$commons.printLog('☆ App.vue mounted domain', domain);

    if (this.$deviceType === 2 && domain) {
      this.$store.commit('alertPopup', { popupType: 'AppInstallPopup' });
      return;
    }

    if (!this.$utils.isNull(this.$route.query.redirectPage) && !this.$utils.isNull(this.$route.query.tvTermId)) {
      const tvInfo = {
        tvTermId: this.$route.query.tvTermId,
        tvTermType: this.$route.query.tvTermType,
        redirectPage: this.$route.query.redirectPage,
      };
      this.localTvInfo = tvInfo;
      this.$store.commit('setTvInfo', tvInfo);
      this.$store.commit('setTvLogin', true);
      this.$utils.printLog('☆ ☆ App.vue mounted tvInfo ===>', JSON.stringify(tvInfo));
    }

    let redirectPage = this.$route.query.redirectPage;

    // jhkim: vue3: 사용 여부 확인
    this.$store.commit('setAppVersion', await this.$bridge.versionName());
    this.$store.commit('saveAutoLoginCookie', await this.$bridge.isAutoLogin());
    this.$store.commit('saveToken', await this.$bridge.pushToken());
    this.$store.commit('saveDeviceId', await this.$bridge.deviceId());
    this.$store.commit('saveExtractPhoneNumber', await this.$bridge.stringValue('extractPhoneNumber'));

    this.$commons.printLog('☆ App.vue mounted afterGetData getIsFirst : ', this.getIsFirst);
    this.$commons.printLog('☆ APP.vue mounted afterGetData getSaveDeviceId : ', this.getSaveDeviceId);
    this.$commons.printLog('☆ APP.vue mounted afterGetData getToken : ', this.getToken);
    this.$commons.printLog('☆ APP.vue mounted afterGetData getAutoLoginCookie : ', this.getAutoLoginCookie);

    /* 푸시진입 기존유저 안드로이드 전달값 조회 및 저장 */
    if (redirectPage && this.$deviceType === 1) {
      this.$utils.printLog('☆ App.vue redirectPage && this.deviceType === 1');
    }

    // 웹
    if (this.$deviceType === 2) {
      //웹사이트 직접 진입
      let redirectPage = this.$route.query.redirectPage;
      const autoLoginCookie = localStorage.getItem('autoLoginYn');

      this.$utils.printLog('☆ App.vue mounted this.$deviceType === 2 this.$route.query.redirectPage : ', this.$route.query.redirectPage);
      this.$utils.printLog('☆ App.vue mounted this.$deviceType === 2 this.$route.query.tvTermId  : ', this.$route.query.tvTermId);

      // if (!this.$utils.isNull(this.$route.query.authToken)) {
      //   this.$commons.printLog('☆ App.vue blackunion init');
      //   this.$commons.printLog('☆ App.vue blackunion authToken : ', this.$route.query.authToken);
      //
      //   const params = {
      //     authToken: this.$route.query.authToken,
      //   };
      //
      //   this.$store.dispatch('buUserLogin', params).then(res => {
      //     this.$commons.printLog('☆ App.vue buUserLogin res : ', res);
      //     if (parseInt(res.resultCode) === 0) {
      //       this.$bridge.setStringValue('session', res.jsessionId);
      //       this.$store.commit('setSessionId', res.jsessionId);
      //       this.$store.commit('setSelfAuthData', {
      //         phoneNo: res.phoneNo,
      //         birthDate: res.birthDate,
      //       });
      //       this.$commons.printLog('☆ App.vue buUserLogin success');
      //       this.$commons.printLog('☆ App.vue buUserLogin success res.birthDate : ', res.birthDate);
      //       this.$commons.printLog(
      //         '☆ App.vue buUserLogin success this.$store.state.tvpoint.selfAuthData : ',
      //         this.$store.state.tvpoint.selfAuthData,
      //       );
      //       if (res.pwdYN === 'Y') {
      //         this.$commons.printLog('☆ App.vue buUserLogin res : ', res);
      //         this.$commons.printLog('☆ App.vue buUserLogin res.loginToken : ', res.loginToken);
      //
      //         const _vue = this;
      //         this.$store.commit('setLoginToken', res.loginToken);
      //         this.$bridge.setStringValue('loginToken', res.loginToken);
      //
      //         this.$commons.printLog('☆ App.vue buUserLogin setLoginToken : ', this.$store.state.tvpoint.loginToken);
      //
      //         this.$utils.sequenceEx([
      //           this.getUser,
      //           this.saveLog,
      //           this.manageAutoTransfer,
      //           this.setAgrment,
      //           function () {
      //             _vue.$store.commit('setAppView', true);
      //             _vue.$store.commit('setIsBack', true);
      //             _vue.$router.replace('/initPage');
      //           },
      //         ]);
      //       } else {
      //         // this.$router.replace('/initPage');
      //         this.$store.commit('setSetPwdState', 'joinPwd');
      //         this.$router.push('/user/SetPwdPage');
      //       }
      //     } else {
      //       this.$store.commit('setAppView', true);
      //       this.$commons.printLog('buUserLogin fail');
      //       this.$router.replace('/initPage');
      //     }
      //   });
      //   return;
      // }

      if (!this.$utils.isNull(this.$route.query.redirectPage) && !this.$utils.isNull(this.$route.query.tvTermId)) {
        const tvInfo = {
          tvTermId: this.$route.query.tvTermId,
          tvTermType: this.$route.query.tvTermType,
          redirectPage: this.$route.query.redirectPage,
        };
        this.localTvInfo = tvInfo;
        this.$store.commit('setTvInfo', tvInfo);
        this.$store.commit('setTvLogin', true);
        this.$utils.printLog('☆ App.vue mounted deviceType === 2 tvInfo  : ', tvInfo);
      }

      if (autoLoginCookie === 'Y') {
        //자동로그인이 설정시
        if (!this.getRefreshYN) {
          this.$store.commit('setInitFlag', true);
          this.autoLogin(autoLoginCookie, redirectPage);
        } else if (redirectPage) {
          this.autoLogin(autoLoginCookie, redirectPage);
        } else {
          this.$store.commit('setRefreshYN', false);
        }
      } else {
        //미설정시
        if (redirectPage) {
          this.$store.commit('setInitFlag', true);
          this.pushNotiEnter(redirectPage);
        } else {
          if (!this.getRefreshYN) {
            this.$store.commit('setAppView', true);
            this.$store.commit('setInitFlag', true);
            this.$router.replace('/initPage');
          } else {
            this.$store.commit('setRefreshYN', false);
          }
        }
      }
    }
    //첫 진입 실행 팝업 관련 로직
    let executionPopup = false;
    let agreePush;

    if ((this.$deviceType === 1 || this.$deviceType === 3) && (await this.$bridge.stringValue('agreePush')) === 'Y') {
      const today = this.$utils.getDate('bar');
      if (today === (await this.$bridge.stringValue('stopSee'))) executionPopup = true;
      agreePush = await this.$bridge.stringValue('agreePush'); // 푸쉬 알림 동의 혹은 미동의 하지 않고 그냥 앱 껏을 경우. 앱 다시 실행시 팝업 노출 여부 판단 하기 위한 값.
      this.$commons.printLog('☆ App.vue mounted agreePush : ', agreePush);
      this.$store.commit('setAgreePushYN', agreePush);
    } else {
      executionPopup = !this.$utils.isNull(this.$utils.getCookie('executionPopup'));
      if (this.$utils.isNull(this.$utils.getCookie('buzvill'))) {
        this.$store.commit('setBuzvillToolTip', 'Y');
      }
    }

    // jhkim: ios의 경우 권한 알림 화면에서 확인 후 agreePushPopup이 출력되도록 수정
    if (agreePush !== 'Y' && this.$deviceType === 1) {
      this.$store.commit('agreePushPopup', true);
    } else if (!executionPopup) {
      this.$utils.printLog('☆ App.vue mounted !executionPopup', executionPopup);
      this.$store.dispatch('executionBannerList');
    }

    // jhkim: 파라미터 추가
    bus.$on('start:loading', param => {
      this.startLoading(param);
    });
    bus.$on('end:loading', param => {
      this.endLoading(param);
    });
    smoothscroll.polyfill();

    // 카카오톡 메시지 호출 설정
    // 1. index.html : script import
    // 2. 카카오 객체 설정 여부 확인 및 등록
    // 3. 카카오 연결 시 401 에러 : https://developers.kakao.com/console/app/517792/config/platform 테스트 아이피 등록.
    if (!window.Kakao.isInitialized()) {
      this.$utils.printLog('☆ APP.vue 카카오 객체 생성');
      // https://developers.kakao.com/console/app/517792
      // 앱키에서 javascript값을 넣는다
      window.Kakao.init('08733cbc15c0ec624e3e740785ee2821');
    }

    // jhkim: saveFirstInit은 앱 최초 실행 여부에 따라 로딩 페이지가 달라진다.
    // 안드로이드는 네이티브에서 자바스크립트 메서드를 직접 호출하고 있다.
    // 위 방식으론 메서드 정합이 너무 많이 일어난다.
    // 정상적인 시나리오에 따라 수정되기 전까진 ios에서는 자바스크립트 메서드를 호출하는 로직은 넣지 말자.
    // 첫 페이지 분기 로직은 1초 후 진행하도록 해야 initPage 렌더링과 겹치지 않는다.
    // 분기 로직 시간은 ios 단말기로 직접 테스트 하면서 적절하게 조절하자.
    // setTimeout으로 시점을 조절하지 않고 isFirstLoad가 false일때 watch 타도록 수정 mjkim
    if (this.$deviceType === 3) {
      const isFirstLoad = await this.$bridge.isFirstLoad();
      this.saveFirstInit(isFirstLoad);
    }
    this.$commons.printLog('☆ App.vue mounted end getIsFirst : ', this.getIsFirst);
    this.$commons.printLog('☆ APP.vue mounted end getSaveDeviceId : ', this.getSaveDeviceId);
    this.$commons.printLog('☆ APP.vue mounted end getToken : ', this.getToken);
    this.$commons.printLog('☆ APP.vue mounted end getAutoLoginCookie : ', this.getAutoLoginCookie);
  },

  beforeUnmount() {
    bus.$off('start:loading');
    bus.$off('end:loading');
  },

  methods: {
    async appPush(newValue) {
      this.$commons.printLog('☆ App.vue appPush init');
      this.$commons.printLog('☆ App.vue appPush : ', newValue);
      this.$commons.printLog('☆ App.vue appPush redirectPage : ', this.$route.query.redirectPage);
      this.$commons.printLog('☆ App.vue appPush tvTermId : ', this.$route.query.tvTermId);
      this.$commons.printLog('☆ App.vue appPush getAutoLoginCookie : ', this.getAutoLoginCookie);
      this.$commons.printLog('☆ App.vue appPush getIsFirst : ', this.getIsFirst);
      this.$commons.printLog('☆ APP.vue appPush getSaveDeviceId : ', this.getSaveDeviceId);
      this.$commons.printLog('☆ APP.vue appPush getToken : ', this.getToken);

      // AOS 안드로이드 소스에서 현재 앱 버전과 파이어베이스 버전 비교하여
      // 현재 앱 < 파이어베이스 일 경우 웹뷰 noticeUpdate 함수 호출, 다시 핏콜라보 앱으로 올 경우 서비스 이용 가능
      // 강제 업데이트 불가 및 파이어베이스 통신 시 실패 간혹 발생하여 클라이언트에서 업데이트 로직 수행.
      // 2023-11-23, jslim
      // AOS 앱 강제 업데이트
      if (this.$deviceType === 1 && !this.$commons.isNull(this.$store.state.tvpoint.appVersion)) {
        let curVersion = this.$store.state.tvpoint.appVersion.split('.');
        const aosVersion = `${curVersion[0]}${curVersion[1]}${curVersion[2]}`;
        this.$commons.printLog('☆vue appPush aosVersion : ', aosVersion);
        this.$commons.printLog('☆vue appPush Number(aosVersion) : ', Number(aosVersion));

        if (Number(aosVersion) < 221) {
          location.href = 'https://play.google.com/store/apps/details?id=com.tvpoint';
          return;
        }
      }

      // IOS 앱 강제 업데이트
      if (this.$deviceType === 3 && !this.$commons.isNull(this.$store.state.tvpoint.appVersion)) {
        let ioscurVersion = this.$store.state.tvpoint.appVersion.split('.');
        const iosVersion = `${ioscurVersion[0]}${ioscurVersion[1]}${ioscurVersion[2]}`;
        this.$commons.printLog('☆vue appPush aosVersion : ', iosVersion);

        // 앱 배포 이후 103 > 104 update mjkim
        if (Number(iosVersion) < 103) {
          await this.$bridge.call('goMarket'); // 동작을 위해서는 1.0.4 앱 배포 필요 mjkim
          return;
        }
      }

      // ISSUE : ios일 경우 mounted에서 redirectPage및 tvTermId 인식하지 못해 appPush 메서드에 추가 mjkim
      this.$commons.printLog('☆ appPush redirectPage : ', this.$route.query.redirectPage);
      this.$commons.printLog('☆ appPush tvTermId : ', this.$route.query.tvTermId);
      if (!this.$utils.isNull(this.$route.query.redirectPage) && !this.$utils.isNull(this.$route.query.tvTermId)) {
        const tvInfo = {
          tvTermId: this.$route.query.tvTermId,
          tvTermType: this.$route.query.tvTermType,
          redirectPage: this.$route.query.redirectPage,
        };
        this.localTvInfo = tvInfo;
        this.$store.commit('setTvInfo', tvInfo);
        this.$store.commit('setTvLogin', true);
        this.$utils.printLog('☆ appPush setTvInfo ===>', JSON.stringify(tvInfo));
      }

      let redirectPage = this.$route.query.redirectPage;

      // 편성 어드민 앱 푸시 알림, jslim
      if (window.location.href.indexOf('linkType=') > -1) {
        this.linkType = Number(window.location.href.split('linkType=')[1].split('&')[0]);
      }

      if (window.location.href.indexOf('linkValue=') > -1) {
        this.linkValue = window.location.href.split('linkValue=')[1].split('&')[0];
      }

      if (window.location.href.indexOf('pushId') > -1) {
        let pushId = window.location.href.split('pushId=')[1].split('&')[0];
        this.$store.commit('setAlarmId', pushId);
      }

      this.$commons.printLog('☆ APP.vue androidData linkType : ', this.$route.query.linkType);
      this.$commons.printLog('☆ APP.vue androidData linkValue : ', this.$route.query.linkValue);

      if (newValue) {
        //자동 로그인 토큰값이 있을 때
        if (this.getAutoLoginCookie === 'Y') {
          //푸시 패스(path)값이 있을 때
          if (redirectPage) {
            this.autoLogin(this.getAutoLoginCookie, redirectPage);
          } else {
            //푸시 패스(path)값이 없을 때
            this.autoLogin(this.getAutoLoginCookie);
          }
        } else {
          //자동로그인 토큰값이 없을 때
          //푸시 패스(path)값이 있을 때
          if (redirectPage) {
            this.pushNotiEnter(redirectPage);
            return;
          }
          this.$bridge.splashOff();
          if (!this.$commons.isNull(this.linkType) && !this.$commons.isNull(this.linkValue)) {
            this.$utils.printLog('푸시알림');
            this.pushLink(this.linkValue, this.linkType);
            return;
          }
          if (!this.getRefreshYN) {
            //없을때
            //TODO - hchwang android 진입 요게 필요한가??
            this.$store.commit('setAppView', true);
            this.$router.replace('/initPage');
          } else {
            this.$store.commit('setRefreshYN', false);
          }
        }
      }
    },
    clickEvent() {
      this.connectPlayStore();
    },
    // jhkim: 파라미터 추가
    startLoading(param) {
      Logger.debug('startLoading: %s', param);
      this.isLoading = true;
    },
    // jhkim: 파라미터 추가
    endLoading(param) {
      Logger.debug('endLoading: %s', param);
      // jhkim: 호출될 때마다 타이머 동작... 체크로직 없음
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    },
    /* 업데이트 안내 팝업 호출 (안드로이드에서 호출)*/
    noticeUpdate() {
      location.href = 'https://play.google.com/store/apps/details?id=com.tvpoint';
    },
    connectPlayStore() {
      if (this.deviceType === 1 || this.deviceType === 2) {
        window.open('https://play.google.com/store/apps/details?id=com.tvpoint');
      } else {
        // TODO : 현재 홈빠 애플 주소
        window.open('https://apps.apple.com/us/app/%ED%99%88%EB%B9%A0/id1547536424');
      }
    },
    /* 앱 권한 요청 후 사용자 거부시 */
    permissionDeny(param) {
      this.$commons.printLog('☆ App.vue permissionDeny init');
      this.$commons.printLog('☆ App.vue permissionDeny init param : ', param);
      this.$commons.printLog('☆ App.vue permissionDeny setTvInfo : ', JSON.stringify(this.$store.state.tvpoint.tvInfo));
      if (param === 'FIRST_INIT') {
        localStorage.setItem('permissionCount', '1');
        if (this.$store.state.tvpoint.tvLogin) {
          this.$router.push(this.$store.state.tvpoint.tvInfo.redirectPage);
        } else {
          if (this.getAutoLoginCookie === 'Y') {
            this.autoLogin(this.getAutoLoginCookie);
          } else {
            this.$commons.printLog('☆ App.vue permissionDeny this.getRefreshYN : ', this.getRefreshYN);
            this.$router.replace('/initPage');
          }
          // this.$router.replace('/initPage');
        }
      } else {
        localStorage.setItem('permissionCount', '2');
        this.$store.commit('alertPopup', {});
      }
    },
    onInit() {
      this.$commons.printLog('☆ onInit 진입 this.deviceType ', this.deviceType);
      this.$commons.printLog('☆ onInit 진입 this.getInitFlag ', this.getInitFlag);

      if ((this.$deviceType === 1 || this.$deviceType === 3) && !this.getInitFlag) {
        this.$commons.printLog('☆ onInit 진입 this.deviceType === 1 && !this.getInitFlag ');
        this.$store.commit('setInitFlag', true);
        bus.$on('end:loading', this.endLoading);
      }
    },
    /* 안드로이드 앱 기존회원 신규 전화권한 획득 안내 이동
      - AOS 11버전 회원 신규 전화권한이 없는 경우 권한동의 안내 페이지로 이동
    */
    async permissionRecheck(param) {
      this.$commons.printLog('☆ permissionRecheck init param ', param);
      await this.$bridge.splashOff();
      // const redirectPage = this.$utils.isNull(this.$route.query.redirectPage) ? '' : this.$route.query.redirectPage;
      //
      // this.$commons.printLog('☆ App.vue permissionRecheck getIsFirst : ', this.getIsFirst);
      // this.$commons.printLog('☆ APP.vue permissionRecheck getSaveDeviceId : ', this.getSaveDeviceId);
      // this.$commons.printLog('☆ APP.vue permissionRecheck getToken : ', this.getToken);
      // this.$commons.printLog('☆ APP.vue permissionRecheck getAutoLoginCoo3kie : ', this.getAutoLoginCookie);
      //
      // if ((await this.$bridge.isAutoLogin()) === 'Y' && (await this.$bridge.stringValue('extractPhoneNumber')) === undefined) {
      //   if (redirectPage) {
      //     this.autoLogin(this.getAutoLoginCookie, redirectPage);
      //   } else {
      //     this.autoLogin(this.getAutoLoginCookie);
      //   }
      // } else {
      //   if (redirectPage) {
      //     this.pushNotiEnter(redirectPage);
      //     return;
      //   }
      //   await this.$bridge.splashOff();
      //   if (!this.getRefreshYN) {
      //     this.$store.commit('setAppView', true);
      //     this.$utils.printLog('☆ APP.vue permissionRecheck');
      //     this.$router.replace('/initPage');
      //   } else {
      //     this.$store.commit('setRefreshYN', false);
      //   }
      // }
      // if (param === 'disagree') {
      //   if ((await this.$bridge.isAutoLogin()) === 'Y' && (await this.$bridge.stringValue('extractPhoneNumber')) === undefined) {
      //     this.$utils.printLog('☆ App.vue permissionRecheck disagree');
      //     if (redirectPage) {
      //       this.autoLogin(this.getAutoLoginCookie, redirectPage);
      //     } else {
      //       this.autoLogin(this.getAutoLoginCookie);
      //     }
      //   } else {
      //     if (redirectPage) {
      //       this.pushNotiEnter(redirectPage);
      //       return;
      //     }
      //     await this.$bridge.splashOff();
      //     if (!this.getRefreshYN) {
      //       this.$store.commit('setAppView', true);
      //       this.$utils.printLog('☆ APP.vue permissionRecheck');
      //       this.$router.replace('/initPage');
      //     } else {
      //       this.$store.commit('setRefreshYN', false);
      //     }
      //   }
      // } else {
      //   await this.$bridge.splashOff();
      //   this.$router.replace('/permissionScreen?id=' + param);
      // }
    },
    /* android permission save */
    savePermission(permissionGroup) {
      this.$commons.printLog('☆ savePermission init ', JSON.stringify(permissionGroup));
      this.$store.commit('savePermission', permissionGroup);
      const notifications = !this.$commons.isNull(permissionGroup.resultData.POST_NOTIFICATIONS);
      this.$commons.printLog('☆ savePermission notifications : ', notifications);
      if (notifications) {
        this.$store.commit('setAosNotifications', true);
      }
    },
    /* 안드로이드 앱 최초실행 여부 결과 저장
      - 최초실행O: 권한페이지로 이동
      - 최초실행X: 안드로이드 전달값 저장
    */
    async saveFirstInit(isTrue) {
      this.$commons.printLog('☆App.vue saveFirstInit isTrue : ', isTrue);
      this.$commons.printLog('☆App.vue saveFirstInit tvLogin : ', this.$store.state.tvpoint.tvLogin);
      this.$commons.printLog('☆App.vue saveFirstInit setTvInfo : ', JSON.stringify(this.$store.state.tvpoint.tvInfo));

      this.$commons.printLog('☆ App.vue saveFirstInit getIsFirst : ', this.getIsFirst);
      this.$commons.printLog('☆ APP.vue saveFirstInit getSaveDeviceId : ', this.getSaveDeviceId);
      this.$commons.printLog('☆ APP.vue saveFirstInit getToken : ', this.getToken);
      this.$commons.printLog('☆ APP.vue saveFirstInit getAutoLoginCookie : ', this.getAutoLoginCookie);
      this.onInit();
      if (isTrue == true) {
        this.$commons.printLog('☆ saveFirstInit 진입 isTrue true : ', isTrue);
        if (!this.$utils.isNull(this.$route.query.redirectPage) && !this.$utils.isNull(this.$route.query.tvTermId)) {
          this.$utils.printLog('☆ App.vue saveFirstInit TV QR');
          const tvInfo = {
            tvTermId: this.$route.query.tvTermId,
            tvTermType: this.$route.query.tvTermType,
            redirectPage: this.$route.query.redirectPage,
          };
          this.localTvInfo = tvInfo;
          this.$store.commit('setTvInfo', tvInfo);
          this.$store.commit('setTvLogin', true);
          this.$utils.printLog('☆ App.vue saveFirstInit tvInfo : ', JSON.stringify(tvInfo));
        }
        this.$store.commit('isFirst', true);
        this.$bridge.splashOff();
        this.$router.replace('/onBoarding');
        if (this.deviceType === 3) {
          // ios일때 진입이미지 사라지도록 수정
          this.$store.commit('setAppView', false);
        }
      } else {
        //앱 최초 실행 할 때마다 보여줘야 해서 값 클리어 시키기
        this.$commons.printLog('☆ saveFirstInit 진입 isTrue false : ', isTrue);
        this.$commons.printLog('☆ App.vue saveFirstInit false getIsFirst : ', this.getIsFirst);
        this.$commons.printLog('☆ APP.vue saveFirstInit false getSaveDeviceId ', this.getSaveDeviceId);
        this.$commons.printLog('☆ APP.vue saveFirstInit false getToken', this.getToken);
        this.$commons.printLog('☆ APP.vue saveFirstInit false getAutoLoginCookie', this.getAutoLoginCookie);

        this.$bridge.setStringValue('paiBalloonNoti', '');
        this.$store.commit('isFirst', false);
        this.$store.commit('saveAutoLoginCookie', await this.$bridge.isAutoLogin());
        this.$store.commit('saveToken', await this.$bridge.pushToken());
        this.$store.commit('saveDeviceId', await this.$bridge.deviceId());
        this.$store.commit('saveExtractPhoneNumber', await this.$bridge.stringValue('extractPhoneNumber'));

        this.$commons.printLog('☆ App.vue saveFirstInit false afterGetData getIsFirst : ', this.getIsFirst);
        this.$commons.printLog('☆ APP.vue saveFirstInit false afterGetData getSaveDeviceId : ', this.getSaveDeviceId);
        this.$commons.printLog('☆ APP.vue saveFirstInit false afterGetData getToken : ', this.getToken);
        this.$commons.printLog('☆ APP.vue saveFirstInit false afterGetData getAutoLoginCookie : ', this.getAutoLoginCookie);
      }
    },

    exitPopup() {
      this.$commons.printLog('☆ App.vue exitPopup');
      let flag = this.$store.state.common.setSlidePopup.flag ?? '';
      this.$commons.printLog('[App.vue] methods exitPopup flag : ', flag);
      if (!this.$store.state.common.exitPopup && flag === 'start') {
        flag = '';
      }
      if (flag === 'start' || flag === 'end') {
        this.$store.commit('setExitPopUp', false);
        // this.$store.commit('setSlideUpPopup', {});
        this.$store.commit('setSlideUpPopup', { showSlide: false, pages: '', height: '' });
      } else {
        if (Object.keys(this.$store.state.fit.executionBannerList).length === 0) {
          this.$commons.printLog('☆ App.vue exitPopup fit.executionBannerList empty');
          this.$store.dispatch('executionBannerList', 'end');
        } else {
          this.$commons.printLog('☆ App.vue exitPopup fit.executionBannerList');
          this.$store.commit('setSlideUpPopup', {
            showSlide: true,
            pages: 'ExecutionBanner',
            height: '518px',
            flag: 'end',
          });
        }
        // this.$store.commit('setExitPopUp', true);
      }
    },
    //Mobile Hardware Back Button Handler
    //router history가 없는 상태에서 이전키 선택 시 호출됨
    backBtnHandler() {
      this.$commons.printLog('☆ App.vue backBtnHandler');
      if (this.endPopupFlag) {
        this.$store.commit('setSlideUpPopup', {});
        return true;
      } else if (this.startPopupFlag) {
        this.$store.commit('setSlideUpPopup', {});
        return true;
      } else {
        this.$store.commit('setClickBack', true);
        return false;
      }
    },
    /* 푸시로 진입 or 외부에서 진입 or 동적링크 진입 시
       라우팅 분기 처리 (URL 파라미터에 pushYn 값이 Y인 경우) */
    pushNotiEnter(redirectPage) {
      this.$commons.printLog(`☆ pushNotiEnter redirectPage : [ ${redirectPage} ]`);
      this.$commons.printLog('☆ pushNotiEnter route.query.id :', this.$route.query.id);
      this.$commons.printLog('☆ pushNotiEnter route.query.benefitId :', this.$route.query.benefitId);

      this.$utils.printLog('☆ pushNotiEnter route.query.redirectPage ===>', this.$route.query.redirectPage);
      this.$utils.printLog('☆ pushNotiEnter route.query.tvTermId ===>', this.$route.query.tvTermId);

      this.$bridge.splashOff();

      let id = this.$route.query.id;
      let benefitId = this.$route.query.benefitId;
      let content_id = this.$route.query.content_id;
      switch (redirectPage) {
        //푸쉬로 들어와서 채널 소식 방으로 보내는 경로
        case '/storeRoom':
          this.$router.replace({
            name: 'storeRoom',
            query: { id: id, content_id: content_id },
          });
          break;
        //채널 소식에서 채널 혜택을 공유 했을때 공유 링크로 채널 소식 창으로 들어가는 경로
        case '/channelBenefit':
          this.$router.replace({
            name: 'storeRoom',
            query: { id: id, benefitId: benefitId },
          });
          break;
        case '/event':
          this.$router.replace(`${redirectPage}?id=${id}`);
          break;
        case '/bbarshop/productDetail':
          this.$router.replace(`${redirectPage}?id=${id}`);
          break;
        case '/bbarshop/exhibitionDetail':
          this.$router.replace(`${redirectPage}?id=${id}`);
          break;
        case '/event/vod': {
          const params = {
            componentId: this.$route.query.componentId,
            groupId: this.$route.query.groupId,
            groupType: this.$route.query.groupType,
            pageId: this.$route.query.pageId,
          };
          this.$router.replace({
            name: 'VodEventDetailView',
            params: { id: id, vodDetail: params },
          });
          break;
        }
        case '/tvpoint/banner':
          {
            this.$router.replace({
              name: 'tvpointBanner',
              params: {
                menuId: this.$route.query.menuId,
                operationId: this.$route.query.operationId,
              },
            });
          }
          break;

        default:
          this.$router.push(redirectPage);
          // this.$router.replace(redirectPage + '?pushYn=Y');
          break;
      }
      if (this.$store.state.tvpoint.isLogin) {
        this.$commons.printLog('☆ pushNotiEnter paiLogin : ' + redirectPage);
        this.paiLogin();
      }
    },

    /* 자동로그인 */
    async autoLogin(cookie, redirectPage) {
      this.$commons.printLog('☆ autoLogin init');

      const userId = await this.$bridge.userId();
      const _vue = this;
      const params = {
        termType: 'M002002',
        deviceId: userId,
      };

      if (!redirectPage && this.$commons.isNull(this.linkType)) {
        this.$bridge.splashOff();
        this.$store.commit('setAppView', true);
      }

      this.$store.dispatch('autoLogin', params).then(res => {
        if (parseInt(res.resultCode) === 0) {
          _vue.$commons.printLog('☆ autoLogin success');
          this.$bridge.setUserId(_vue.$utils.makeNumber(res.celNo));
          this.$bridge.setStringValue('session', res.jsessionId);
          _vue.$store.commit('setLoginToken', res.loginToken);

          if (redirectPage) {
            this.$utils.sequenceEx([
              this.getUser,
              this.saveLog,
              this.manageAutoTransfer,
              this.getAgrmntList,
              function () {
                _vue.$store.commit('setIsBack', true);
                _vue.$commons.printLog('☆ autoLogin success redirectPage : ', redirectPage);
                _vue.pushNotiEnter(redirectPage);
              },
            ]);
          } else if (!this.$commons.isNull(this.linkType) && !this.$commons.isNull(this.linkValue)) {
            this.$utils.printLog('☆ autoLogin success linkValue : ', this.linkValue);
            this.$utils.printLog('☆ autoLogin success linkType : ', this.linkType);
            if (_vue.deviceType === 1) {
              this.$bridge.splashOff();
            }
            this.$utils.sequenceEx([
              this.getUser,
              this.saveLog,
              this.manageAutoTransfer,
              this.getAgrmntList,
              function () {
                _vue.pushLink(_vue.linkValue, _vue.linkType);
                _vue.paiLogin();
              },
            ]);
          } else {
            this.$utils.sequenceEx([
              this.getUser,
              this.saveLog,
              this.manageAutoTransfer,
              this.getAgrmntList,
              this.$router.push('/initPage'),
              function () {
                if (_vue.deviceType === 3) {
                  // ios일때 진입이미지 사라지도록 수정
                  _vue.$store.commit('setAppView', false);
                }
                _vue.$store.commit('setIsBack', true);
                _vue.$commons.printLog('☆ autoLogin success initPage');
                _vue.paiLogin();
              },
            ]);
          }
        } else {
          this.$bridge.setStringValue('userPhone', '');
          this.$bridge.setStringValue('userId', '');
          this.$bridge.setStringValue('session', '');
          this.$bridge.setStringValue('reqDate', '');
          this.$bridge.setStringValue('access_token', '');
          this.$bridge.setStringValue('renewal_token', '');
          this.$bridge.setStringValue('loginToken', '');
          this.$bridge.setStringValue('autoLoginYn', 'N');

          // 스토어 정보 날리기
          this.$store.commit('resetPointState');
          this.$store.commit('resetRpState');
          this.$store.commit('resetUserState');
          this.$store.commit('resetTvpayState');
          this.$store.commit('resetPaiState');
          this.$commons.printLog('☆ autoLogin fail resultCode : ', res.resultCode);
          //공유하기로 들어올 경우 웹 과 앱 로그인 상태 동기화 필요하기 때문에 조건 처리.
          this.$commons.printLog('☆ autoLogin fail redirectPage : ', redirectPage);
          if (redirectPage === '/channelBenefit') {
            _vue.$store.commit('isLogin', false);
            this.deviceType === 1 ? this.$bridge.setAutoLogin('N') : localStorage.setItem('autoLoginYn', '');
            _vue.pushNotiEnter(redirectPage);
          } else if (redirectPage) {
            _vue.pushNotiEnter(redirectPage);
          } else if (!this.$commons.isNull(this.linkType) && !this.$commons.isNull(this.linkValue)) {
            this.$utils.printLog('☆ autoLogin fail linkType : ', this.linkType);
            this.$utils.printLog('☆ autoLogin fail linkValue : ', this.linkValue);
            this.$bridge.splashOff();
            _vue.pushLink(_vue.linkValue, _vue.linkType);
          } else {
            this.$utils.printLog('☆ autoLogin fail initPage');
            this.$bridge.splashOff();
            _vue.$router.push('/initPage');
            if (_vue.deviceType === 3) {
              // ios일때 진입이미지 사라지도록 수정
              _vue.$store.commit('setAppView', false);
            }
          }
        }
      });
    },
    saveLog() {
      this.$utils.printLog('☆ App.vue saveLog init');

      const _vue = this;
      const deferred = this.$utils.defer();
      const logMessage = 'tvinfo : [' + JSON.stringify(_vue.localTvInfo) + '] index.vue';

      const params = {
        sessionId: _vue.$store.getters.getSession,
        deviceId: '',
        tvTermId: Object.keys(_vue.localTvInfo).length === 0 ? '' : _vue.localTvInfo.tvTermId,
        logMessage,
      };
      _vue.$store.dispatch('saveLog', params);

      deferred.resolve();
      return deferred.promise;
    },
    getUser() {
      const _vue = this;
      const deferred = this.$utils.defer();
      _vue.$utils.printLog('☆ App.vue getUser init');

      const data = _vue.$store.dispatch('getUser', { termType: _vue.getTermType });

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('getUser success data : ', data);
          // 전달 받은 정보 저장
          _vue.$store.commit('saveGetUser', data);
          // 비밀번호 설정 시 휴대폰번호와 생년월일 설정 불가로 앱카드 로그인 한 회원 정보 저장
          _vue.$store.commit('setUserCel', _vue.$utils.makeNumber(data.celNo));
          _vue.$store.commit('setUserBirthDate', data.userBirthDay);
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    /** 자동전환 리스트 가져오기 */
    manageAutoTransfer() {
      const _vue = this;
      const deferred = _vue.$utils.defer();
      const data = _vue.$store.dispatch('manageAutoTransfer', { manageType: 'S', termType: _vue.getTermType });

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          if (data.dataYN === 'Y') {
            _vue.$store.commit('isAutoTrans', 'Y');
            _vue.$store.commit('setAutoTransDetail', data);
          } else {
            _vue.$store.commit('isAutoTrans', 'N');
          }
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    /** 화면 기준 사용자의 동의 정보 목록 호출 */
    getAgrmntList() {
      const _vue = this;
      const deferred = this.$utils.defer();

      _vue.$utils.printLog('☆ App.vue getAgrmntList init');

      const data = _vue.$store.dispatch('getAgrment', { code: 'AGR_0001', clientType: _vue.getDeviceName });

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          if (data.isYN !== 'Y') {
            deferred.reject([_vue, data, 'setAgrmntNewTerms']);
          } else {
            _vue.$store.commit('setAgrmntList', data);
            deferred.resolve();
          }
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    // setAgrment() {
    //   const _vue = this;
    //   _vue.$utils.printLog('☆ App.vue setAgrment init');
    //   const deferred = this.$utils.defer();
    //   const data = _vue.$store.dispatch('setAgrment', { code: 'AGR_0001', clientType: _vue.getDeviceName, isYN: 'Y' });
    //
    //   data.then(data => {
    //     _vue.$utils.printLog('☆ App.vue setAgrment resultCode', data.resultCode);
    //     _vue.$utils.printLog('☆ App.vue setAgrment data', data);
    //     if (parseInt(data.resultCode) === 0) {
    //       deferred.resolve();
    //     } else {
    //       deferred.resolve();
    //     }
    //   });
    //   return deferred.promise;
    // },
  },
};
</script>

<style lang="scss" scoped>
#app {
  //width: 100%;
  //height: 100vh;
}
#img {
  width: 100%;
  height: 100vh;
  background-size: contain;
  transition: transform 300ms ease-in-out;
}
</style>
