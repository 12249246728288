<template>
  <div>
    <div class="common-padding content-wrap">
      <!--   스토어   -->
      <section v-if="filterValue === 'store'">
        <div v-for="(item, idx) in dataList" :key="idx" class="d-flex section">
          <div>
            <input :id="item.company_id" :ref="item.company_id" type="checkbox" name="store" class="check-all" :value="item.name" />
            <label :for="item.company_id"></label>
          </div>
          <CommonText font-size="30" font-color="#222222" spacing="-1.5" line-height="104">{{ item.name }}</CommonText>
        </div>
      </section>
      <!--   가격대   -->
      <section v-else style="margin-top: 23px">
        <div class="d-flex center" style="margin-bottom: 8px">
          <CommonText font-size="24" font-color="#76787a" spacing="-1.2" line-height="35" style="width: 258px; margin-right: 41px"
            >최저금액</CommonText
          >
          <CommonText font-size="24" font-color="#76787a" spacing="-1.2" line-height="35">최고금액</CommonText>
        </div>
        <div class="d-flex center">
          <input v-model="leftValue" type="text" class="input" />
          <common-text font-size="40" font-color="#222222" spacing="-2" line-height="47" style="margin: 0 8px"> ~ </common-text>
          <input v-model="rightValue" type="text" class="input" />
        </div>
        <!--				<div class="multi-range-slider" style="position: relative; margin-top: 80px; height: 40px; width: 100%">-->
        <!--					<input type="range" id="input-left" min="1" max="100" value="1" v-model="leftValue" @change="setLeft" />-->
        <!--					<input type="range" id="input-right" min="1" :max="maxPrice" value="100" v-model="rightValue" @change="setRight" />-->
        <!--					<div class="slider">-->
        <!--						<div class="track"></div>-->
        <!--						<div class="range" ref="range"></div>-->
        <!--						<span class="thumb" ref="thumbLeft" style="left: 0; transform: translate(0, -15px)"></span>-->
        <!--						<span class="thumb" ref="thumbRight" style="right: 0; transform: translate(0px, -15px)"></span>-->
        <!--					</div>-->
        <!--				</div>-->
      </section>
    </div>
    <EmptyPaddingBar :padding-height="110" />
    <div class="btn-wrap">
      <CommonRadiusButton color="#dadcde" width="230px" height="110px" class="btn" @click-event="clickEvent('reset')">
        <CommonText font-size="32" font-color="#76787a" spacing="-1.6">선택 초기화</CommonText>
      </CommonRadiusButton>
      <CommonRadiusButton color="#2b2d2f" width="490px" height="110px" class="btn" @click-event="clickEvent('select')">
        <CommonText font-size="32" font-color="#ffffff" spacing="-1.6">상품보기</CommonText>
      </CommonRadiusButton>
    </div>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
import CommonRadiusButton from '@/components/common/button/CommonRadiusButton';
import EmptyPaddingBar from '@/components/common/EmptyPaddingBar';

export default {
  name: 'FilterPopUp',

  components: { EmptyPaddingBar, CommonRadiusButton, CommonText },
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  emits: ['closePopup'],

  data() {
    return {
      leftValue: '',
      rightValue: '',
      dataList: [],
      minPrice: '',
      maxPrice: '',
    };
  },
  computed: {
    filterValue() {
      return this.$store.state.bbarshop.filterValue;
    },
  },

  async created() {
    let params = '';
    if (this.$route.query.name) {
      params = '&name=' + this.$route.query.name;
    } else if (this.$route.query.tag) {
      params = '&tag=' + this.$route.query.tag;
    }
    if (this.filterValue === 'store') {
      await this.$store.dispatch('storeFilters', params).then(data => {
        this.$store.commit('setStore', data);
        this.dataList = data;
      });
    } else if (this.filterValue === 'price') {
      let priceValue = [];
      await this.$store.dispatch('priceFilters', params).then(data => {
        priceValue = data;
        this.minPrice = priceValue.min_price;
        this.maxPrice = priceValue.max_price;
        this.leftValue = priceValue.min_price;
        this.rightValue = priceValue.max_price;
      });
    }
  },
  updated() {
    if (this.filterValue === 'store') {
      let storeFilter = this.$route.query.companys;
      if (storeFilter && storeFilter.length > 0) {
        let storeArray = storeFilter.split(',');
        storeArray.forEach((data, idx) => {
          this.$refs[data][0].checked = true; // 체크박스에 체크가 되어잇도록
        });
      }
    }
  },
  methods: {
    clickEvent(sort) {
      switch (sort) {
        case 'reset':
          var query = Object.assign({}, this.$route.query);
          if (this.filterValue === 'store') {
            this.$store.commit('setStore', {});
            delete query.companys;
            this.$router.replace({ query });
          } else if (this.filterValue === 'price') {
            this.$store.commit('setPrice', []);
            delete query.min_price;
            delete query.max_price;
            this.$router.replace({ query });
          }
          //팝업창 닫기
          this.$emit('closePopup');
          // window.location.reload();
          break;
        case 'select':
          var queryString = window.location.href.split('searchresult')[1];
          if (this.filterValue === 'store') {
            const query = 'input[name="store"]:checked';
            const selectedEls = document.querySelectorAll(query);

            let store = [];
            let storeId = [];
            selectedEls.forEach(el => {
              store.push(el.value);
              storeId.push(el.id);
            });
            this.$store.commit('setStore', { companys: store });
            this.$store.commit('setSearchStoreId', { companys: storeId });
            let queryStore = '';
            if (this.$route.query.companys) {
              if (this.$route.query.min_price) {
                queryStore =
                  queryString.split('&companys')[0] + '&companys=' + storeId + '&min_price=' + queryString.split('&min_price=')[1];
              } else {
                queryStore = queryString.split('&companys')[0] + '&companys=' + storeId;
              }
            } else {
              queryStore = queryString + '&companys=' + storeId;
            }
            this.$router.replace('/bbarshop/searchresult' + queryStore);
            // window.location.reload();
            this.$emit('closePopup');
          } else if (this.filterValue === 'price') {
            if (this.leftValue > this.rightValue) {
              this.$store.commit('alertPopup', {
                popupType: 'ErrorPopup',
                popupContent: '최고금액보다 최저금액이 더 큽니다.',
                popupTitle: '',
              });
              this.leftValue = this.minPrice;
              this.rightValue = this.maxPrice;
            } else {
              this.$store.commit('setPrice', [{ min_price: this.leftValue }, { max_price: this.rightValue }]);
              let queryPrice = '';
              if (this.$route.query.min_price) {
                queryPrice = queryString.split('&min_price')[0] + '&min_price=' + this.leftValue + '&max_price=' + this.rightValue;
              } else {
                queryPrice = queryString + '&min_price=' + this.leftValue + '&max_price=' + this.rightValue;
              }
              this.$router.replace('/bbarshop/searchresult' + queryPrice);
              // window.location.reload();
              this.$emit('closePopup');
            }
          }
          break;
      }
    },
    setRight(e) {
      const inputLeft = document.getElementById('input-left');
      const _this = e.target;
      const { value, min, max } = _this;

      if (+value - +inputLeft.value < 10) {
        _this.value = +inputLeft.value + 10;
      }

      const percent = ((+_this.value - +min) / (+max - +min)) * 100;

      this.$refs.thumbRight.style.right = `${100 - percent}%`;
      this.$refs.range.style.right = `${100 - percent}%`;
    },
    setLeft(e) {
      const inputRight = document.getElementById('input-right');
      const _this = e.target;
      const { value, min, max } = _this;

      if (+inputRight.value - +value < 10) {
        _this.value = +inputRight.value - 10;
      }

      const percent = ((+_this.value - +min) / (+max - +min)) * 100;

      this.$refs.thumbLeft.style.left = `${percent}%`;
      this.$refs.range.style.left = `${percent}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  align-items: center;
}
.content-wrap {
  height: 660px;
  overflow: auto;
}
.btn-wrap {
  width: 100%;
  height: 110px;
  display: flex;
  position: fixed;
  bottom: 0;
}
.section {
  align-items: center;
  border-bottom: 1px solid #dadcde;
}
.section:last-child {
  border-bottom: 0;
}
.check-all:checked + label {
  margin-right: 16px;
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  background: url('../../../assets/img/tvpoint/check_circle_on.svg') no-repeat;
  box-sizing: border-box;
}

.check-all {
  display: none;
  float: left;
  box-sizing: border-box;
}

.check-all + label {
  margin-right: 16px;
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  background: url('../../../assets/img/tvpoint/check_circle_off.svg') no-repeat;
  box-sizing: border-box;
}
.input {
  width: 258px;
  height: 88px;
  border-radius: 8px;
  background-color: #f2f3f5;
  border: 0;
  font-family: Pretendard;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -2px;
  color: #222;
  text-indent: 24px;
}
.multi-range-slider {
  padding: 0 10px;
  box-sizing: border-box;
}
.slider {
  position: relative;
  width: 600px;
  height: 8px;
  margin: 0 auto;
}
.track {
  position: absolute;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #e6e8ea;
}
.range {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: #2b2d2f;
}
input[type='range'] {
  -webkit-appearance: none;
  position: absolute;
  appearance: none;
  pointer-events: none;
  z-index: 10;
  height: 8px;
  width: 620px;
  //opacity: 0;
}
input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  width: 50px;
  height: 50px;
  border: 0 none;
  -webkit-appearance: none;
}
.thumb {
  position: absolute;
  z-index: 3;
  width: 40px;
  height: 40px;
  display: block;
  box-shadow: 2px 2px 8px 0 rgba(118, 120, 122, 0.3);
  border: solid 4px #2b2d2f;
  box-sizing: border-box;
  border-radius: 100%;
  background-color: white;
}
</style>
