<template>
  <div>
    <div class="input-wrapper">
      <input
        v-model="inputValue"
        :class="sort == 'orderPaymentDetail' ? 'grey' : 'white'"
        maxlength="13"
        type="text"
        placeholder="P로 시작하는 13자리 개인통관고유부호"
      />
      <img v-if="inputValue.length > 0" :src="require('@/assets/img/bbarshop/btn_delete.svg')" alt="" @click="deleteInput" />
    </div>
    <border-basic margin="40" height="1" background="#dadcde" style="width: 100%" />
    <div>
      <div class="d-flex c-center" style="margin-top: 40px">
        <img
          :src="isAgree1 ? require('@/assets/img/common/check_circle_on.svg') : require('@/assets/img/common/check_circle_off.svg')"
          style="margin-right: 16px"
          @click="clickEvent('term1')"
        />
        <common-text font-size="26" spacing="-1.3" font-color="#2b2d2f">[필수] 개인통관고유부호 수집 동의</common-text>
      </div>
      <div class="d-flex c-center" style="margin-top: 24px">
        <img
          :src="isAgree2 ? require('@/assets/img/common/check_circle_on.svg') : require('@/assets/img/common/check_circle_off.svg')"
          style="margin-right: 16px"
          @click="clickEvent('term2')"
        />
        <common-text font-size="26" spacing="-1.3" font-color="#2b2d2f">[필수] 국내정보 국외이전 동의</common-text>
      </div>
    </div>
  </div>
</template>

<script>
import { fitMixin } from '@/utils/fitMixin';
import CommonText from '@/components/common/text/CommonText';
import BorderBasic from '@/components/common/border/BorderBasic';

export default {
  name: 'PersonalCodeFrame',
  components: { BorderBasic, CommonText },

  mixins: [fitMixin],

  props: {
    sort: {
      type: String,
      default: '',
    },
    btnClick: {
      type: Boolean,
      default: false,
    },
    deleteData: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['btnActivate'],

  data() {
    return {
      isMounted: false,
      isAgree1: false,
      isAgree2: false,
      inputValue: '',
    };
  },

  computed: {
    personalCode() {
      return this.$store.state.bbarshop.personalCode;
    },
  },

  watch: {
    inputValue(newValue) {
      if (newValue.length > 12) {
        this.btnActive();
      } else {
        // this.$emit('btnActivate', false);
      }
    },
    btnClick() {
      if (this.inputValue.length < 13) {
        this.$store.commit('alertPopup', {
          popupType: 'ErrorPopup',
          popupContent: '유효하지 않은 개인통관고유부호입니다. <br>개인통관고유부호를 확인해주세요.',
          popupTitle: '',
        });
      } else if (!this.isAgree1 || !this.isAgree2) {
        this.$store.commit('alertPopup', {
          popupType: 'ErrorPopup',
          popupContent: '필수 동의사항을 선택해주세요.',
          popupTitle: '알림',
        });
      }
    },
  },

  methods: {
    clickEvent(sort) {
      switch (sort) {
        case 'term1':
          this.isAgree1 = !this.isAgree1;
          this.btnActive();
          break;
        case 'term2':
          this.isAgree2 = !this.isAgree2;
          this.btnActive();
          break;
      }
    },
    btnActive() {
      if (this.inputValue.length == 13 && this.isAgree1 && this.isAgree2) {
        this.inputValue = this.inputValue.toUpperCase();
        if (!this.deleteData) {
          if (this.personalCode) {
            // 개인통관부호가 있을시에는 수정
            this.$store
              .dispatch('personalCodeUpdate', { personal_identification: { pi: this.inputValue } })
              .then(data => {
                if (!this.$commons.isNull(data)) {
                  this.$store.commit('setPersonalCode', data.personal_identification.pi);
                  this.$emit('btnActivate', true);
                } else {
                  this.$store.commit('alertPopup', {
                    popupType: 'ErrorPopup',
                    popupContent: '유효하지 않은 개인통관고유부호입니다. <br>개인통관고유부호를 확인해주세요.',
                    popupTitle: '',
                  });
                  this.$emit('btnActivate', false);
                }
              })
              .catch(data => {
                this.$store.commit('alertPopup', {
                  popupType: 'ErrorPopup',
                  popupContent: '유효하지 않은 개인통관고유부호입니다. <br>개인통관고유부호를 확인해주세요.',
                  popupTitle: '',
                });
                this.$emit('btnActivate', false);
              });
          } else {
            // 없을시엔 등록
            this.$store
              .dispatch('personalCodeAdd', { personal_identification: { pi: this.inputValue } })
              .then(data => {
                if (data.personal_identification) {
                  this.$store.commit('setPersonalCode', data.personal_identification.pi);
                  this.$emit('btnActivate', true);
                } else {
                  this.$store.commit('alertPopup', {
                    popupType: 'ErrorPopup',
                    popupContent: '유효하지 않은 개인통관고유부호입니다. 개인통관고유부호를 확인해주세요.',
                    popupTitle: '',
                  });
                  this.$emit('btnActivate', false);
                }
              })
              .catch(data => {
                this.$store.commit('alertPopup', {
                  popupType: 'ErrorPopup',
                  popupContent: '유효하지 않은 개인통관고유부호입니다. <br>개인통관고유부호를 확인해주세요.',
                  popupTitle: '알림',
                });
                this.$emit('btnActivate', false);
              });
          }
        } else {
          // 주문자와 수령인이 다를 경우
          // P+숫자12자리 형식이 아니면 팝업
          const regex = /^P\d{12}$/;
          if (!regex.test(this.inputValue)) {
            this.$store.commit('alertPopup', {
              popupType: 'ErrorPopup',
              popupContent: '유효하지 않은 개인통관고유부호입니다. <br>개인통관고유부호를 확인해주세요.',
              popupTitle: '알림',
            });
          } else {
            this.$store.commit('setPersonalCode', this.inputValue);
            this.$emit('btnActivate', true);
          }
        }
      } else {
        // this.$emit('btnActivate', false);
      }
    },
    deleteInput() {
      this.inputValue = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: 100%;
  position: relative;
  & input {
    width: 100%;
    height: 80px;
    border-radius: 8px;
    padding: 18px 18px 18px 32px;
    box-sizing: border-box;
    font-size: 28px;
    text-transform: uppercase;
    caret-color: #4a5cff;
    font-family: 'Noto Sans KR';
    color: #2b2d2f;
  }
  & input::placeholder {
    font-size: 28px;
  }
  & input:focus {
    box-shadow: 0 0 0 4px rgba(100, 140, 255, 0.2);
    border: solid 2px #648cff;
    background-color: #ffffff;
  }
  & img {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
.grey {
  background-color: #f2f3f5;
}
.grey::placeholder {
  color: #999b9c;
}
.white {
  border: solid 1px #dadcde;
  background-color: #ffffff;
}
.white::placeholder {
  color: #a8aaac;
}
</style>
