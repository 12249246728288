import $commons from '@/utils/commons';
import $store from '@/store';
import $utils from '@/utils/tvPointcommon.js';
import { fitMixin } from '@/utils/fitMixin';
import bus from '@/utils/bus';
import $bridge from '@/utils/FTBridgeShared';

const common = [
  {
    path: '/bbarshop/orderList',
    name: 'OrderList',
    component: () => import('@/views/orderPayment/orderList'),
    async beforeEnter(to, from, next) {
      bus.$emit('start:loading');
      let tvPayUserNo = $store.state.orderPayment.tvPayUserNo
        ? $store.state.orderPayment.tvPayUserNo
        : await $bridge.shared().stringValue('mUserNo');
      let authToken = await $bridge.shared().stringValue('loginToken');
      const params = {
        userNo: tvPayUserNo,
        userType: '0', //현재는 가맹점이 없어 0으로 통일 espark
        mallCode: 'MOBILETP01',
        authToken: authToken,
        appcardSubUrl: '/FIT-ORDER-RP-V1/order/getOrderStore.mv',
      };
      if (!$store.state.tvpoint.isLogin) {
        next();
      } else {
        $store.dispatch('fitReqRelay', params).then(data => {
          if (data.resultCode === '0000') {
            $store.commit('setGetOrderStore', data.resultData.list);
            // next();
          } else if (data.resultCode === '-2010') {
            $store.commit('alertPopup', { popupType: 'LoginPopup' });
          } else if (data.resultCode === '0001') {
            // next();
          } else {
            $commons.printLog('주문내역조회 error');
            // next();
          }
        });
      }
      next();
      if (to.query.id) {
        delete $store.state.fit.pageInform.pageInfo.buttonInfo;
        $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;

        $store.state.fit.pageInform.pageInfo = {};
        $store.state.fit.pageInform.pageInfo.page_id = to.query.id;
        $store.state.fit.pageInform.pageInfo.title = to.query.title;
        $store.state.fit.pageInform.pageInfo.url = to.query.uri;
        $store.state.fit.pageInform.pageInfo.depth = parseInt(to.query.depth);
        $store.state.fit.pageInform.pageInfo.pageAttribute = { type: 2, event_id: to.query.id };

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }

        if (!$commons.isNull($store.state.fit.pageInform.prevPageInfo)) {
          await $store.dispatch('informEvent', $store.state.fit.pageInform);
        }
      }
      bus.$emit('end:loading');
    },
  },
  {
    path: '/bbarshop/orderListDetail',
    name: 'OrderListDetail',
    component: () => import('@/views/orderPayment/orderListDetail'),
    async beforeEnter(to, from, next) {
      let storeId = to.query.storeId;
      const moment = require('moment');
      const yy = moment().format('YYYY');
      const mm = moment().format('MM');
      let yy1 = '';
      let nextM = '';
      let thisM = '';
      let mm1 = Number(mm) + 1;

      let m1 = '';
      if (mm1 < 10) {
        m1 = '0' + mm1;
        nextM = yy + m1 + '01';
        thisM = yy + mm + '01';
      } else if (mm1 > 12) {
        yy1 = Number(yy) + 1;
        m1 = '0' + (mm1 - 12);
        nextM = yy1 + m1 + '01';
        thisM = yy + mm + '01';
      } else {
        m1 = mm1;
        nextM = yy + m1 + '01';
        thisM = yy + mm + '01';
      }

      let reqDate = thisM + '||' + nextM;
      let tvPayUserNo = $store.state.orderPayment.tvPayUserNo
        ? $store.state.orderPayment.tvPayUserNo
        : await $bridge.shared().stringValue('mUserNo');
      let authToken = await $bridge.shared().stringValue('loginToken');
      const params = {
        tvpayUserNo: tvPayUserNo,
        storeId: storeId,
        reqPage: '1',
        reqCount: '20',
        reqDate: reqDate,
        authToken: authToken,
        appcardSubUrl: '/FIT-ORDER-RP-V1/order/getOrderTrans.mv',
      };
      $store.dispatch('fitReqRelay', params).then(data => {
        if (data.resultCode === '0000') {
          $store.commit('setGetOrderTrans', data.resultData.list);
          // next();
        } else {
          $commons.printLog('주문내역조회 error');
        }
      });
      next();
    },
  },
  {
    path: '/bbarshop/orderComplete',
    name: 'OrderComplete',
    component: () => import('@/views/orderPayment/orderComplete'),
    async beforeEnter(to, from, next) {
      let storeId = to.query.storeId;
      let tid = to.query.tid;
      let tvPayUserNo = $store.state.orderPayment.tvPayUserNo
        ? $store.state.orderPayment.tvPayUserNo
        : await $bridge.shared().stringValue('mUserNo');
      let authToken = await $bridge.shared().stringValue('loginToken');
      const params = {
        tvpayUserNo: tvPayUserNo,
        storeId: storeId,
        tid: tid,
        authToken: authToken,
        appcardSubUrl: '/FIT-ORDER-RP-V1/order/getOrderDetail.mv',
      };
      let data = await $store.dispatch('fitReqRelay', params);
      $store.commit('setGetOrderTrans', data.resultData);

      //상품구매등록 완료 통계
      const params2 = {
        id: $store.state.bbarshop.productBuyAttempt.id,
        trade: {
          tvpay_tid: tid,
        },
      };
      let data2 = await $store.dispatch('orderComplete', params2);
      $store.commit('setProductBuyAttempt', data2);

      next();
    },
  },
  {
    path: '/bbarshop/newAddress',
    name: 'NewAddress',
    component: () => import('@/views/orderPayment/newAddress'),
    async beforeEnter(to, from, next) {
      next();
    },
  },
  {
    path: '/bbarshop/orderDetailHistory',
    name: 'OrderDetailHistory',
    component: () => import('@/views/orderPayment/orderDetailHistory'),
    async beforeEnter(to, from, next) {
      let tvPayUserNo = $store.state.orderPayment.tvPayUserNo
        ? $store.state.orderPayment.tvPayUserNo
        : await $bridge.shared().stringValue('mUserNo');
      let authToken = await $bridge.shared().stringValue('loginToken');
      const params = {
        tvpayUserNo: tvPayUserNo,
        storeId: to.query.storeId,
        tid: to.query.tid,
        authToken: authToken,
        appcardSubUrl: '/FIT-ORDER-RP-V1/order/getOrderDetail.mv',
      };
      await $store.dispatch('fitReqRelay', params).then(data => {
        if (data.resultCode === '0000') {
          $store.commit('setGetOrderDetail', data.resultData);
          next();
        } else {
          $commons.printLog('주문상세조회 error');
        }
      });
    },
  },
  {
    path: '/bbarshop/zoomImg',
    name: 'ZoomImg',
    component: () => import('@/views/orderPayment/zoomImg'),
    async beforeEnter(to, from, next) {
      next();
    },
  },
  //
  {
    path: '/bbarshop/deliveryAddrManage',
    name: 'DeliveryAddrManage',
    component: () => import('@/views/bbarShop/deliveryAddrManage'),
    async beforeEnter(to, from, next) {
      let tvPayUserNo = $store.state.orderPayment.tvPayUserNo
        ? $store.state.orderPayment.tvPayUserNo
        : await $bridge.shared().stringValue('mUserNo');
      const params = {
        tvpayUserNo: tvPayUserNo,
        reqCount: 5,
        reqPage: 1,
      };
      const data = await $store.dispatch('fitGetAddr', params);
      if (data.resultCode === '-2010') {
        $store.commit('alertPopup', { popupType: 'LoginPopup' });
      } else {
        $store.commit('setDeliveryAddr', data);
        $store.commit('setClickCount', 0); //router.go() 초기화
        next();
      }
    },
  },
  {
    path: '/bbarshop/registerAddr',
    name: 'RegisterAddr',
    component: () => import('@/views/bbarShop/registerAddr'),
    async beforeEnter(to, from, next) {
      const data = to.query.item;
      $store.commit('setAddrUpdate', data);
      next();
    },
  },
  {
    path: '/bbarshop/orderPaymentDetail',
    name: 'OrderPaymentDetail',
    component: () => import('@/views/orderPayment/orderPaymentDetail'),
    async beforeEnter(to, from, next) {
      bus.$emit('start:loading');

      // fitGetAddr
      let tvPayUserNo = $store.state.orderPayment.tvPayUserNo
        ? $store.state.orderPayment.tvPayUserNo
        : await $bridge.shared().stringValue('mUserNo');
      const params = {
        tvpayUserNo: tvPayUserNo,
        reqCount: 0,
        reqPage: 1,
      };
      const data = await $store.dispatch('fitGetAddr', params);
      $store.commit('setDeliveryAddr', data);

      // insSessionData
      let hmbDataKey;
      await $store
        .dispatch('insSessionData', {
          termType: $store.getters.getTermType,
          clientType: $store.getters.getDeviceType,
        })
        .then(res => {
          if (parseInt(res.resultCode) === 0) {
            $store.commit('ssKey', res.resultData.hmbDataKey);
            hmbDataKey = res.resultData.hmbDataKey;
          } else {
            $utils.printLog();
          }
        });
      // getSessionData
      await $store.dispatch('getSessionData', { dataKey: $store.getters.ssKey }).then(res => {
        if (parseInt(res.resultCode) === 0) {
          $store.commit('userDetailInfo', res.resultData);
          $store.commit('setRpSession', res.resultData.jSession);
        } else {
          $utils.printLog();
        }
      });
      // getFitAccountList
      let cardParams = {
        tvpaySubUrl: `api/getFitAccountList.mv;jsessionid=${$store.state.tvpoint.rpSession}`,
        loginCheck: 'true',
      };
      await $store.dispatch('callRelayRpServer', cardParams).then(data => {
        if (data.resultCode === '0000') {
          const list = data.resultData.list.filter(data => data.isMobileUser === 'Y');
          $utils.printLog('list : ', list);
          $store.commit('setPayCardList', list);

          let cardCode = '';
          if (
            (!$commons.isNull($store.state.common.sourceId) && $store.state.common.sourceId === 'FITSH00001') ||
            $store.state.common.sourceId === 'FITKB00001' ||
            $store.state.common.sourceId === 'FITSS00001' ||
            $store.state.common.sourceId === 'FITHD00001' ||
            $store.state.common.sourceId === 'FITBC00001' ||
            $store.state.common.sourceId === 'FITNH00001' ||
            $store.state.common.sourceId === 'FITHN00001' ||
            $store.state.common.sourceId === 'FITLT00001'
          ) {
            const card_id = $store.state.common.sourceId.substring(3, 5);

            switch (card_id) {
              case 'SH':
                cardCode = '2088';
                break;
              case 'KB':
                cardCode = '2004';
                break;
              case 'SS':
                cardCode = '2066';
                break;
              case 'HD':
                cardCode = '2077';
                break;
              case 'BC':
                cardCode = '2050';
                break;
              case 'NH':
                cardCode = '1011';
                break;
              case 'HN':
                cardCode = '2081';
                break;
              case 'LT':
                cardCode = '2055';
                break;
            }
            const cardList = list.filter(list => list.cardCode === cardCode);
            $utils.printLog('cardList : ', cardList);
            $store.commit('setPayCardList', cardList);
          }

          if (!$commons.isUndefined($store.state.orderPayment.payCardList) && !$commons.isNull($store.state.orderPayment.payCardList)) {
            $store.state.orderPayment.cardSelected = $store.state.orderPayment.payCardList[0];
            $store.commit('setSelectDiscount', { instType: '1', name: '일시불', installment: '00' });
          } else if (
            $commons.isUndefined($store.state.orderPayment.payCardList) ||
            $commons.isNull($store.state.orderPayment.payCardList)
          ) {
            $store.state.orderPayment.cardSelected.cardCompany = 'newCard';
          }

          // if (data.resultData.list && data.resultData.list !== 0) {
          //   $store.state.orderPayment.cardSelected = $store.state.orderPayment.payCardList[0];
          //   $store.commit('setSelectDiscount', { instType: '1', name: '일시불', installment: '00' });
          // } else if (data.resultData.list.length === 0) {
          //   $store.state.orderPayment.cardSelected.cardCompany = 'newCard';
          // }
        } else {
          $utils.printLog();
          alert(data.resultMessage);
        }
      });

      next();
      bus.$emit('end:loading');
    },
  },
  {
    path: '/bbarshop/orderErrorPage',
    name: 'OrderEmptyPage',
    component: () => import('@/components/orderPayment/OrderEmptyPage'),
    async beforeEnter(to, from, next) {
      next();
    },
  },
];

export default common;
